@import "./variables.scss";

@mixin groups-wrapper($padding: 16px 24px, $color: $gray_secondary_900) {
  width: 100%;
  padding: $padding;
  color: $color !important;
}

@mixin groups-title(
  $font-weight: 600,
  $margin-bottom: 4px,
  $line-height: 21px,
  $color: $gray_secondary_900
) {
  font-family: "Inter-600" !important;
  font-weight: $font-weight;
  line-height: $line-height;
  margin-bottom: $margin-bottom;
  color: $color !important;
}

@mixin groups-subtitle(
  $font-weight: 400,
  $margin-bottom: 40px,
  $line-height: 21px,
  $color: $gray_secondary_900,
  $font-size: 14px
) {
  font-family: "Inter-300" !important;
  font-weight: $font-weight;
  line-height: $line-height;
  margin-bottom: $margin-bottom;
  color: $gray_secondary_600 !important;
  font-size: $font-size;
}

@mixin product-title(
  $color: $gray_secondary_900,
  $width: fit-content,
  $maxWidth: fit-content
) {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color;
  width: $width;
  max-width: $maxWidth;
}
