@import "@shared/ui/_variables.scss";

.complete-current-price {
  background-color: rgba($green-100, 0.3);
}
.group-items__pagination-container {
  padding-top: 24px;
}
.brand-w-style {
  max-width: 270px;
}
.store-w-style {
  max-width: 240px;
}
.resize-block-brand {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
}
.resize-block-store {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
}
.custom-tooltip-items {
  display: inline-block;
}
.na-current-price {
  color: $gray_secondary_600;
}
