
@import "@shared/ui/_variables.scss";

.title__content {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-ipdate__wrapper{
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.refresh__ico {
  cursor: pointer;
}

.content-update__text {
  font-weight: 400!important;
  line-height: 20px!important;
  color: $gray_secondary_600!important;
  font-family: 'Inter-300';
  margin-right: 4px;
}