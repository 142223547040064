@import "@shared/ui/_variables.scss";

.modal-products-without-competitors {
  width: 86% !important;
  display: flex;

  .modal__footer {
    border-top: 1px solid $gray_secondary_300;
  }
  .modal__content {
    margin: 16px 24px 0px 24px;
    min-height: 480px;
  }
  .no-data-block {
    border-top: 1px solid $gray_secondary_300;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
    margin-top: 16px;
    .no-data__text {
      text-align: center;
      div {
        color: $gray_secondary_900;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .groups-edite-table-no-competitors {
    overflow: auto;
    max-height: 480px;
  }
}
