@import "@shared/ui/_variables.scss";

.custom-checkbox {

  &.gray-primary-900 {

    &:hover {
      .ant-checkbox-inner {
        border-color: $gray_secondary_900!important;
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner{
        background-color: $gray_secondary_900!important;
        border-color: $gray_secondary_900!important;
      }
     }

    .ant-checkbox:hover {
      .ant-checkbox-inner{
        border-color: $gray_secondary_900;
      }
     }
  }
}