@import "@shared/ui/mixins.scss";

.modal-exceptions {
  min-width: 1200px;
}

.modal__exceptions-content {
  padding: 16px 24px 3px 24px;
}

.modal__search-block {
  margin-bottom: 16px;
}

.suitable-active {
  background-color: $yellow-100 !important;
}

.modal-exceptions__table {
  height: 50vh;
  overflow: auto;
}
.warning-info {
  font-size: 14px;
}
