@import "@shared/ui/_variables.scss";

.not-found-page {
  height: 100vh;
  padding: 16px 24px;

  .not-found-logo {
    color: $gray_secondary_900;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    height: 60px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 12px;
  }
  .not-found-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 165px);
    .not-found-content__title {
      color: $gray_secondary_900;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 52px;
      margin-bottom: 8px;
    }
  }
  .not-found-title {
    color: $gray_secondary_900;
    text-align: center;
    font-size: 130px;
    font-style: normal;
    font-weight: 600;
    line-height: 156px;
  }
  .not-found-text {
    color: $gray_secondary_900;
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: 67.2px;
    margin: 8px 0px;
  }
  .not-found-subtext {
    color: $gray_secondary_900;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
  }
  .not-found-button {
    margin-top: 32px;
  }
}
