@import "@shared/ui/_variables.scss";

.input-custom__wrapper {
  text-align: left;
  position: relative;

  .input__right-icon-bg {
    position: absolute;
    transform: translateY(-50%);
    z-index: 1;
    top: 50%;
    height: 100%;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 6px;
    width: 28px;
    border-bottom-right-radius: 6px;
    border: 1px solid $gray_secondary_300;
    background: $gray_secondary_100;
    pointer-events: none;
    transition: border-color 0.2s ease, background-color 0.2s ease;
  }

  &:hover .input__right-icon-bg,
  &.input-custom__wrapper--focused .input__right-icon-bg {
    border-left: 1px solid $gray_secondary_300;
    border-right: 1px solid $gray_secondary_900;
    border-top: 1px solid $gray_secondary_900;
    border-bottom: 1px solid $gray_secondary_900;
  }

  .input__right-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  &.input-icon__padding-right {
    input {
      padding-right: 37px;
    }
  }

  &.gray-primary-900 {
    input {
      &:focus,
      &:hover {
        border-color: $gray_secondary_900;
        box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.1);
      }
    }
  }

  input {
    &::placeholder {
      font-family: "Inter-300" !important;
    }
  }
}
