@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";
.modal__content {
  overflow: hidden;
}
.main-block {
}

.items__btn-next {
  width: 88px;
}

.items__header {
  th {
    position: relative;
    right: 0;
    left: 0;
  }
}

.product__subtitle {
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  font-family: "Inter-300" !important;
  color: $gray_secondary_600 !important;
  text-align: left;
}

.product__title {
  @include product-title($gray_secondary_900 !important);
  width: 290px;
}

.product__img {
  height: 37px;
  // max-width: 37px;
}

.products__table {
  height: 540px;
  overflow-y: auto;
  margin-right: 24px;
  padding-left: 24px;
}

.pagination__container {
  border-top: 1px solid $gray_secondary_200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
}
.selected__block {
  display: flex;
  align-items: center;
}

.selected__count {
  font-family: "Inter-500" !important;
  font-weight: 600;
}
.selected__text {
  font-family: "Inter-300" !important;
  font-weight: 400;
  margin-left: 3px;
}
.noData__products {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 476px;
  color: $gray_secondary_900;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.modal-products {
  width: 86% !important;
  display: flex;
}
.products__table-block {
  height: 50vh;
  color: $gray_secondary_900;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.ungrouped {
  color: $gray_secondary_600;
}
.modal__info-alert {
  margin: 12px 24px 0px 24px;
}
.modal__info-alert > div > svg {
  height: 20px;
  width: 20px;
}
