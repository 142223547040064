@import "@shared/ui/_variables.scss";

.stepper__wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .error-link {
    cursor: pointer;
    // text-decoration: underline;
    border-bottom: 1px solid $gray_secondary_900;
  }
  .summary-alert {
    position: absolute;
    bottom: 94px;
    left: 342px;
    width: 852px;
  }
}

.stepper__container {
  display: flex;
  flex: 1;
}

.ant-steps-item-container {
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
}

.stepper__aside {
  width: 264px;
  border-right: 1px solid $gray_secondary_300;
  display: flex;
  justify-content: center;
  padding: 16px;
}

/********* ANT STEPER *********/
.ant-steps-vertical {
  width: 100% !important;
  height: 308px;
}

.ant-steps-item-wait {
  .ant-steps-item-container {
    cursor: default;
  }
}

.ant-steps-item {
  display: flex !important;
  align-items: center !important;
  padding: 0 12px 0 8px !important;

  &.item__highlighted {
    background: $gray_secondary_200;
    border-radius: 4px;
  }
}

.ant-steps-item-icon {
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  top: 0 !important;
  background-color: transparent !important;
  border: 1px solid $gray_secondary_300 !important;
  margin-right: 10px !important;

  .ant-steps-icon {
    font-size: 12px !important;
    font-family: "Inter-500" !important;
    font-weight: 600 !important;
    color: $gray_secondary_600 !important;
    top: 0 !important;
  }
}

.ant-steps-item-tail {
  top: 14px !important;
  padding: 23px 0 6px !important;
  width: 2px !important;
  inset-inline-start: 18px !important;

  &::after {
    background: $gray_secondary_300 !important;
  }
}

.ant-steps-item-title {
  font-size: 16px !important;
  line-height: 20px !important;
  color: $gray_secondary_600 !important;
  font-family: "Inter-300" !important;
  font-weight: 400 !important;
}

.ant-steps-item-content {
  min-height: 42px !important;
  display: flex !important;
  align-items: center;
}

/********* STEPE CURRENT *********/
.ant-steps-item-active {
  .ant-steps-item-container {
    cursor: pointer;
  }

  .ant-steps-item-icon {
    background: $gray_secondary_900 !important;
  }

  .ant-steps-icon {
    color: $gray_default_10 !important;
  }

  .ant-steps-item-title {
    font-family: "Inter-500" !important;
    font-weight: 600 !important;
    color: $gray_secondary_900 !important;
  }
}

/********* STEPE FINISHED *********/
.ant-steps-item-finish {
  .ant-steps-item-container {
    cursor: pointer;
  }

  .ant-steps-item-icon {
    border-color: transparent !important;
  }

  .ant-steps-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px !important;
    height: 22px !important;
  }

  .ant-steps-item-title {
    font-family: "Inter-500" !important;
    font-weight: 600 !important;
  }
}

/********* LAYOUT CHILDREN *********/
.children__content {
  display: flex;
  flex: 1;
  position: relative;
}

.stepper__actions {
  border-top: 1px solid $gray_secondary_300;
  padding: 24px;
  display: flex;
  justify-content: space-between;
}

.action__left {
  display: flex;

  button {
    width: 85px;
  }
}

.action__right {
  display: flex;

  button {
    &:first-child {
      width: 118px;
      margin-right: 16px;
    }

    &:last-child {
      width: 88px;
    }

    &.groups-complete__btn {
      width: 107px;
    }
  }
}

.item__title-wrapper-wait {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 175px;
  .item__icon-warning {
    display: flex;
    align-items: center;
  }
}
.alert-custom-all-errors {
  margin: 12px 24px;
}
