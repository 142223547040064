@import "@shared/ui/_variables.scss";

.legend-block {
  color: $gray_secondary_900;
  text-align: center;
  font-size: 12px;
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
}

.today-text {
  color: $gray_secondary_600;
  text-align: center;
  font-size: 12px;
  position: absolute;
  bottom: -4px;
  right: 0;
  transform: translateX(-50%);
  bottom: 13px;
  right: -6px;
}
.tooltip-label {
  text-align: start;
  font-weight: 800;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 800;
}

.custom-tooltip-block {
  position: absolute;
  transform: translate(-50%, 0);
  background-color: rgba($gray_secondary_900, 0.9);
  text-align: center;
  color: white;
  padding: 10px;
  border-radius: 5px;
  pointer-events: none;
  font-size: 14px;
  width: max-content;
  z-index: 10000;

  .tooltip-arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba($gray_secondary_900, 0.9);
  }
}
.custom-tooltip-block * {
  opacity: 1;
}

.badge-item-color-box {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border: 1px solid $white;
}

.tooltip-item {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}
.legend-block-elasticity {
  color: $gray_secondary_900;
  text-align: center;
  font-size: 12px;
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: 54%;
  justify-content: space-evenly;
  .legend-block-elasticity-item-sales {
    display: flex;
    align-items: center;
    .block {
      width: 12px;
      height: 12px;
      background-color: $gray_secondary_400;
      margin-right: 8px;
    }
    .block-text {
      color: $gray_secondary_900;
      font-size: 12px;
    }
  }
  .legend-block-elasticity-item-profit {
    display: flex;
    align-items: center;
    margin: 0px 8px;
    .block-custom-profit {
      position: relative;
      width: 42px;
      height: 1px;
      background-color: $blue-500;
      margin: 20px auto;
      margin-right: 8px;
    }
    .block-custom-profit::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 4px;
      height: 4px;
      background-color: white;
      border: 1.5px solid $blue-500;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
  .legend-block-elasticity-item-revenue {
    display: flex;
    align-items: center;
    margin: 0px 8px;
    .block-custom-revenue {
      position: relative;
      width: 42px;
      height: 1px;
      background-color: $green-500;
      margin: 20px auto;
      margin-right: 8px;
    }
    .block-custom-revenue::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 4px;
      height: 4px;
      background-color: white;
      border: 1.5px solid $green-500;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
  .sales-label-y {
    position: absolute;
    left: -136px;
    transform: rotate(-90deg);
    top: -147px;
  }
  .performance-label-y {
    position: absolute;
    right: -136px;
    transform: rotate(-90deg);
    top: -147px;
  }
}
