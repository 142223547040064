@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.groups-price-limits__product-for-exception {
  padding: 32px;
  border: 1px solid $gray_secondary_300;
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;

  font-family: "Inter-300" !important;
  font-weight: 400;

  .groups-price-limits__product {
    display: flex;
    .groups-price-limits__product-info {
      display: flex;
      margin-right: 30px;
      width: 70%;

      .product__img {
        width: 44px;
        margin-right: 8px;
        height: auto;
        width: fit-content;
        max-height: 44px;
      }
      .groups-price-limits__product-name {
        display: flex;
        flex-direction: column;
      }
      .groups-price-limits__article {
        font-size: 12px;
        color: $gray_secondary_600;
        line-height: 18px;
      }
      .groups-price-limits__name {
        font-size: 14px;
        color: $gray_secondary_900;
        line-height: 21px;
      }
    }
  }

  .groups-price-limits__product-actions {
    display: flex;

    .groups-price-limits__product-actions-item {
      margin-right: 45px;

      &:last-child {
        margin-right: 0;
      }
    }

    .groups-price-limits__product-item {
      font-size: 14px;
      color: $gray_secondary_600;
      line-height: 20px;
      width: max-content;
    }
    .groups-price-limits__product-item-value {
      font-size: 16px;
      color: $gray_secondary_900;
      line-height: 21px;
    }
  }

  .groups-price-limits__choose-block-limit {
    flex: 1;
    box-sizing: border-box;
  }

  .groups-price-limits__choose-block {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .msrp-active {
      flex: 1;
      margin-right: 30px;
    }

    .groups-price-limits__choose-block-option {
      margin-left: 16px;
      .chooser {
        display: flex;
        height: 64px;
        align-items: end;
        justify-content: space-between;
        label {
          line-height: 36px;
        }
      }
    }

    .groups-price-limits__choose-block-minus {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 16px;
    }

    .title {
      font-size: 14px;
      color: $gray_secondary_900;
      line-height: 20px;
      margin-bottom: 6px;
    }

    .margin-input-exception {
      width: 100px;
      font-size: 16px;
      text-align: center;
    }

    .currentPrice-input-exception {
      width: 100px;
      font-size: 16px;
      text-align: center;
    }

    .msrp-input-exception {
      width: 110px;
      font-size: 16px;
      text-align: center;
    }
  }

  .trash-icon {
    cursor: pointer;
    display: flex;
    position: absolute;
    right: -22px;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
    background: $white;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.error-validation-message-exception {
  justify-content: center;
  position: absolute;
  color: $danger_500;
  left: 0px;
  font-size: 12px;
  bottom: -19px;
  width: max-content;
}
.error-validation-message-exception-msrp {
  position: absolute;
  color: $danger_500;
  left: 210px;
  bottom: -22px;
  width: max-content;
}

/////////////////////

.modal__remove-competitor-content {
  padding: 24px;
  .modal__remove-competitor-text {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
.modal-remove-competitor-title {
  padding: 24px;
  color: $gray_secondary_900;
  font-size: 21px;
  font-weight: 600;
  line-height: 25.2px;
}

.modal__remove-competitor-footer {
  padding: 24px;
}

@media (min-width: 1800px) {
  .groups-price-limits__product {
    .groups-price-limits__product-info {
      width: auto !important;
      flex: 1;
    }
  }

  .groups-price-limits__choose-block-limit {
    width: auto !important;
    flex: 1;
  }
}
