@import "@shared/ui/_variables.scss";

.back_to_container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;

  h5 {
    color: $gray_secondary_900;
    font-weight: 400;
    font-family: "Inter-400";
    margin-left: 8px;
  }
}
.title__content {
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}
