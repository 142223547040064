@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.groups-price-limits__wrapper {
  @include groups-wrapper();
  overflow: auto;
}

.groups-price-limits__title {
  @include groups-title();
}

.groups-price-limits__subtitle {
  @include groups-subtitle();
}

.groups-price-limits__content {
  .divider {
    margin: 24px 0px;
    max-width: 833px;
    min-width: 833px;
  }
  .groups-price-limits__no-data {
    margin: 24px 0px 16px 0px;
    font-size: 16px;
    color: $gray_secondary_600;
  }

  .groups-price-limits__input {
    width: 98px;
  }
  .groups-price-limits__input-current-price {
    width: 80px;
  }

  .inputClassNameLeft {
    font-size: 16px;
  }
  .inputClassNameCenter {
    text-align: center;
    font-size: 16px;
  }
  .groups-price-limits__exception-title-table {
    padding-right: 20px;
    position: relative;
    height: 80px;
  }
  .groups-price-limits__exception-title-table-fixed {
    padding-right: 20px;
    position: relative;
    height: 62px;
    display: flex;
    align-items: flex-start;
  }
  .groups-price-limits__exception {
    height: 440px;
    // overflow-x: auto;
    padding-right: 21px;
  }
  .error-validation-message {
    position: absolute;
    margin-top: 58px;
    color: $danger_500;
    width: max-content;
  }
  .info-error-block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 53px;
    svg {
      margin-right: 4px;
    }
    span {
      color: $danger_500;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.groups-price-limits__text {
  color: $gray_secondary_900;
  font-family: "Inter-600";
}

.groups-price-limits__select {
  min-width: 200px !important;
  max-width: 200px !important;
  margin-right: 16px;
  margin-left: 24px;
}

.groups-price-limits__switch {
  margin-right: 16px;
}
.groups-price-limits-block {
  min-height: 40px;
}
.groups-price-limits__select-wrapper {
  position: relative;

  .error-validation-message {
    position: absolute;
    margin-top: 5px;
    color: $danger_500;
    left: 25px;
  }
}
.groups-price-limits__no-exception {
  font-size: 16px;
  color: $gray_secondary_600;
  font-family: "Inter-300";
  position: absolute;
}
.groups-price-limits__warning {
  margin: 24px 0px;
  max-width: 833px;
}
.groups-price-limits__exception-wrapper {
  width: 98%;
}
.error-validation-message {
  position: absolute;
  font-size: 12px;
  margin-top: 6px;
  color: $danger_500;
}

.groups-price-limits__description {
  position: absolute;
  bottom: -18px;
  left: 5px;
  color: $gray_secondary_600;
  font-family: "Inter-300";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}
.collapsed-block-main {
  background-color: white;
  position: fixed;
  top: 77px;
  min-height: 60px;
  width: 894px;
  left: 343px;
  padding: 24px;
  border-bottom: 1px solid $gray_secondary_300;
  z-index: 1;
}
.groups-price-limits__collapsed-block-warning {
  z-index: 1;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.groups-price-limits__collapsed-block {
  z-index: 1;
  display: flex;
  align-items: center;
}
.collapsed-block {
  display: flex;
  align-items: center;
  flex: 10;
  div {
    margin-left: 20px;
  }
}
.groups-price-limits__collapsed-open-block {
  background-color: white;
  position: fixed;
  top: 71px;
  width: 894px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid $gray_secondary_300;
  left: 343px;
}
.collapsed-block-icon {
  cursor: pointer;
}
.groups-price-limits__checkboxes-block {
  display: flex;
  align-items: baseline;
  margin-top: 15px;
}
.margin-input {
  margin-left: 16px;
}
.chooser-checkbox-limit {
  display: flex;
  align-items: center;
  height: 38px;
  margin-left: 18px;
  label {
    margin-right: 4px;
  }
}
.select-error {
  bottom: 14px;
}
.disableInput {
  pointer-events: none;
  background-color: $gray_secondary_100;
  * {
    background-color: $gray_secondary_100;
  }
}
.groups-price-limits__check-block {
  height: 52px;
}
