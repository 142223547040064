@import "@shared/ui/_variables.scss";

.tabs-container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $gray_secondary_300;
  overflow: hidden;
  flex-grow: 1;
}

.tabs-list {
  display: flex;
  flex-direction: column;
  width: 264px;
  border-right: 1px solid $gray_secondary_300;

  padding: 16px 24px;
}

.tab-item {
  position: relative;
  padding: 12px 16px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid $gray_secondary_300;
  margin: 0px 10px;
  color: $gray_secondary_600;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: 44px;

  &.active {
    font-weight: bold;
    color: $gray_secondary_900;

    &::before {
      content: "";
      position: absolute;
      left: -1px;
      top: 0;
      height: 100%;
      width: 2px;

      background: $gray_secondary_900;
    }
  }

  &.bold {
    font-weight: bold;
  }
  .error-icon {
    position: absolute;
    right: 0px;
  }
}

.tab-content {
  flex: 1;
  position: relative;
}
