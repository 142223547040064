@import "@shared/ui/_variables.scss";

.check-code {
  margin-bottom: 24px;
  .verify-code__container-error {
    input {
      border: 1px solid $danger_500 !important;
      box-shadow: 0 0 0 3px $danger_200 !important;
      &:hover {
        border: 1px solid $danger_500 !important;
        box-shadow: 0 0 0 3px $danger_200 !important;
      }
      &:active {
        border: 1px solid $danger_500 !important;
        box-shadow: 0 0 0 3px $danger_200 !important;
      }
      &:focus {
        border: 1px solid $danger_500 !important;
        box-shadow: 0 0 0 3px $danger_200 !important;
      }
      &:focus-within {
        border: 1px solid $danger_500 !important;
        box-shadow: 0 0 0 3px $danger_200 !important;
      }
    }
  }
  .email {
    color: $gray_secondary_900;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  .verify-form__resend-title {
    color: $gray_secondary_600;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .verify-form__resend-text {
    .clicked {
      color: $gray_secondary_600;
      position: absolute;
      bottom: -5px;
      left: 67px;
      width: max-content;
    }
    .clicked-timer {
      position: absolute;
      bottom: -9px;
      left: 174px;
      width: max-content;
    }
  }
  .check-code-block {
    margin-bottom: 24px;
  }
}
