@import "@shared/ui/_variables.scss";

.groups-strategy-rule__table {
  tbody {
    border-bottom: 1px solid $gray_secondary_200;
    tr {
      border-top: 1px solid $gray_secondary_200;
    }
  }
}
