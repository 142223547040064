@import "@shared/ui/_variables.scss";

.filter__item {
  height: 38px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-top: 1px solid $gray_secondary_200;
  border-bottom: 1px solid $gray_secondary_200;
  border-right: 1px solid $gray_secondary_200;

  .filter__text {
    font-family: "Inter-300" !important;
    font-weight: 400 !important;
  }

  .filter__text-badge {
    color: $gray_secondary_600;
  }

  &.filter__item-active {
    background-color: $gray_secondary_300;

    &:hover {
      background-color: $gray_secondary_300;
    }
  }

  &:hover {
    background-color: $gray_secondary_100;
    cursor: pointer;
  }

  &:first-child {
    border-radius: 4px 0px 0px 4px;
    border-left: 1px solid $gray_secondary_200;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }
}
