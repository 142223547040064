@import "@shared/ui/_variables.scss";
.item-price-elasticity {
  .block-elasticity {
    margin: 20px 24px 24px 24px;
    .elasticity-title {
      font-family: "Inter-600";
      color: $gray_secondary_900;
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 26%;
    }
  }
  .item-price-elasticity-input {
    width: 355px;
    color: $gray_secondary_900;
    font-size: 16px;
    line-height: 20px;
    padding: 0px 16px;
    height: 38px;
  }
  .section-price {
    padding: 28px 24px 30px 24px;
    position: relative;
    .item-price-elasticity-form {
      width: max-content;
    }
    .save-price-btn {
      width: 80px;
      margin-left: 16px;
    }
    .price-title {
      min-width: 75px;
      margin-right: 16px;
      width: max-content;
    }
    .item-price-elasticity-input-label {
      position: absolute;
      bottom: 13px;
      color: $gray_secondary_600;
      font-size: 12px;
      line-height: 12px;
      left: 114px;
    }
  }
  .section-impact {
    padding: 16px 24px;
    .impact-title {
      font-family: "Inter-600";
      color: $gray_secondary_900;
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      margin-bottom: 16px;
    }
    .impact-item {
      width: 250px;
      padding: 16px 24px;
      height: 82px;
      border-radius: 8px;
      border: 1px solid $gray_secondary_300;
      margin: 8px;
      .item-label {
        color: $gray_secondary_600;
        font-size: 14px;
        line-height: 20px;
      }
      .item-profit-up {
        font-size: 14px;
        line-height: 20px;
        color: $green-500;
      }
      .item-profit-down {
        font-size: 14px;
        line-height: 20px;
        color: $danger_500;
      }
      .item-before {
        color: $gray_secondary_900;
        font-size: 18px;
        line-height: 21px;
      }
      .item-after {
        color: $gray_secondary_900;
        text-align: right;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
      }
      .item-arrow {
        transform: rotate(-180deg);
      }
    }
    .impact-items {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .empty-graph {
    background-color: $gray_secondary_100;
    border: 1px solid $gray_secondary_300;
    width: auto;
    margin: 16px;
    height: 427px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-title {
      margin-top: 12px;
      margin-bottom: 8px;
      color: $gray_secondary_900;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
    }
    &-text {
      color: $gray_secondary_600;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .empty-graph-forecast {
    background-color: $gray_secondary_100;
    border: 1px solid $gray_secondary_300;
    width: auto;
    // margin: 16px;
    height: 200px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-title {
      margin-top: 12px;
      margin-bottom: 8px;
      color: $gray_secondary_900;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
    }
    &-text {
      color: $gray_secondary_600;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .positive {
    background-color: $green-100;
    border-radius: 3px;
  }
  .negative {
    background-color: $danger_100;
    border-radius: 3px;
  }
  .item-arrow-up {
    position: absolute;
    bottom: 144px;
    right: 317px;
  }
  .item-arrow-down {
    position: absolute;
    bottom: 135px;
    right: 317px;
    transform: rotate(-180deg);
  }
  .item-arrow-up-sales {
    position: absolute;
    bottom: 144px;
    right: 52px;
  }
  .item-arrow-down-sales {
    position: absolute;
    bottom: 135px;
    right: 52px;
    transform: rotate(-180deg);
  }
  .item-arrow-up-profit {
    position: absolute;
    bottom: 44px;
    right: 317px;
  }
  .item-arrow-down-profit {
    position: absolute;
    bottom: 35px;
    right: 317px;
    transform: rotate(-180deg);
  }
  .item-arrow-up-revenue {
    position: absolute;
    bottom: 44px;
    right: 52px;
  }
  .item-arrow-down-revenue {
    position: absolute;
    bottom: 35px;
    right: 52px;
    transform: rotate(-180deg);
  }
}
