@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.groups-empty__wrapper {
  @include groups-wrapper();
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 200px);
}

.groups-empty__title {
  @include groups-title();
  margin-bottom: 8px;
}

.groups-empty__subtitle {
  @include groups-subtitle();
  margin-bottom: 24px;
}

.groups-empty__btn {
  width: 130px;
}
.groups__alert {
  position: absolute;
  top: 80px;
  left: 16px;
  width: calc(100% - 32px);
}

.groups-edite__wrapper {
  // width: calc(100% - 600px);
  padding: 16px 24px 0;
  color: $gray_secondary_900 !important;
}

.groups-edite__title {
  font-family: "Inter-600" !important;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 4px;
  color: $gray_secondary_900 !important;
}

.groups-edite__suptitle {
  color: $gray_secondary_900 !important;
  font-family: "Inter-400" !important;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 40px;
}

.groups-header {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  padding: 16px 0px;
}

.groups-edite-table {
  overflow: auto;
  max-height: calc(100vh - 408px);
}
.groups-block-delete__btn {
  margin-left: 24px;
  .groups-delete__btn {
    border-color: $danger_300;
    color: $danger_500;
  }

  .groups-delete__btn:hover {
    border-color: $danger_500 !important;
    color: $danger_500 !important;
  }
}
.confirm-modal-body-info {
  margin: 24px;
  li {
    margin-left: 23px;
  }
}
.groups-edite__wrapper-hold {
  position: absolute;
  top: 16px;
  left: 24px;
  width: max-content;
  text-align: start;
}
