@import "@shared/ui/_variables.scss";

.layout__container {
  display: flex;
  background: $gray_secondary_900;
  height: 100vh;
}

.aside__container {
  width: 248px;
  min-width: 248px;
  display: flex;
  flex-direction: column;
}

.aside__menu {
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.demo-logo-vertical {
  padding: 0 24px 0 43px;
  margin: 40px 0 16px;
  color: $gray_secondary_100;

  h4 {
    font-size: 20px !important;
    font-weight: 400;
    font-family: "Inter-300";
    color: $gray_secondary_100;
  }
}

.aside__menu-bottom {
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 16px;
}

.aside__menu-top {
  width: 100%;
}

.aside__item {
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  height: 44px;
  position: relative;
  ///
  font-size: 16px !important;
  font-weight: 400;
  color: $gray_secondary_100;
  line-height: 28px;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;

  ///

  &:hover {
    background-color: $gray_secondary_800 !important;
    text-decoration: none;
  }

  .aside__link,
  .aside__link h5 {
    font-size: 16px !important;
    font-weight: 400;
    font-family: "Inter-300";
    color: $gray_secondary_100;
    line-height: 28px;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none;
  }
  .aside__link {
    a {
      text-decoration: none;
    }
  }

  .aside__item-ico {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }

  &.aside__arrow {
    &:hover {
      background-color: transparent !important;
      text-decoration: none;
    }
  }

  .aside__actions {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translatey(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gray_secondary_800;
  }
}

.aside__container-active {
  width: 79px;
  display: flex;
  flex-direction: column;

  .aside__arrow {
    svg {
      transform: rotate(180deg);
    }
  }

  .demo-logo-vertical {
    padding-left: 15px;
  }
}
.aside__link--active {
  text-decoration: none;

  > div,
  h5 {
    background-color: $gray_secondary_800 !important;
    font-size: 16px !important;
    font-weight: 400;
    color: $gray_secondary_100;
    line-height: 28px;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
  }
}
.aside__menu-top {
  .aside__item-ico {
    width: 24px;
    height: 24px;
  }
  div {
    color: initial !important;
    text-decoration: none !important;
    width: -webkit-fill-available;

    // &:hover {
    //   color: $gray_secondary_900;
    //   text-decoration: none !important;
    //   text-underline-offset: 0px !important;
    //   text-decoration-color: $gray_secondary_900;
    // }
  }
}
