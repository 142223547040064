@import "@shared/ui/_variables.scss";

.edit-password {
  padding: 24px 32px;
  &-form_wrapper {
    width: 300px;
    position: relative;
    .input-label__wrapper {
      margin-bottom: 24px;
    }

    .edit-password__btn {
      margin-top: 24px;
    }
    .custom-height {
      position: relative;
      height: 82px;
    }
    .validation-item {
      display: flex;
      align-items: center;
      color: $gray_secondary_600;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: start;
      margin-bottom: 4px;
      .error-validation {
        color: $danger_500;
      }
      span {
        margin-left: 8px;
      }
    }
    .error-validation-text {
      color: $danger_500;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      text-align: start;
      position: absolute;
      top: 65px;
      width: max-content;
    }
  }
}
