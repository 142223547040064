@import "@shared/ui/_variables.scss";

.badge-role_viewer,
.badge-role_admin,
.badge-role_editor,
.badge-role_owner,
.badge-role_manager {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.badge-role_owner {
  background-color: $green-100;
  color: $green-700;
}

.badge-role_viewer {
  background-color: $gray_secondary_200;
  color: $gray_secondary_700;
}
.badge-role_admin {
  background-color: $indigo-100;
  color: $indigo-700;
}
.badge-role_manager {
  background-color: $blue-100;
  color: $blue-700;
}
.badge-role_pending {
  background-color: $yellow-100;
  color: $yellow-700;
  font-size: 12px;
}

.users-table-block {
  max-height: calc(100vh - 383px);
  overflow: auto;
}
