@import "@shared/ui/_variables.scss";

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.badge.round {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.badge.oval {
  // position: relative;
  // padding: 4px 8px;
  // border-radius: 12px;

  position: relative;
  padding: 2px 8px;
  border-radius: 14px;
}
