$black: #000;
$white: #fff;

$gray_default_1: #736e6e;
$gray_default_5: #212527;
$gray_default_10: #fbfcfc;
$gray_secondary_100: #f8f9fa;
$gray_secondary_200: #e9ecef;
$gray_secondary_300: #dee2e6;
$gray_secondary_400: #ced4da;
$gray_secondary_500: #adb5bd;
$gray_secondary_600: #6c757d;
$gray_secondary_700: #495057;
$gray_secondary_800: #343a40;
$gray_secondary_900: #212529;

$danger_100: #f8d7da;
$danger_200: #f1aeb5;
$danger_300: #ea868f;
$danger_400: #e35d6a;
$danger_500: #dc3545;
$danger_600: #b02a37;
$danger_700: #842029;
$danger_800: #58151c;
$danger_900: #2c0b0e;

$indigo-100: #e0cffc;
$indigo-700: #3d0a91;

$green-100: #d1e7dd;
$green-200: #a3cfbb;
$green-300: #75b798;
$green-400: #479f76;
$green-500: #198754;
$green-600: #146c43;
$green-700: #0f5132;
$green-800: #0a3622;
$green-900: #051b11;

$blue-100: #cfe2ff;
$blue-500: #0d6efd;
$blue-700: #084298;
$blue-800: #052c65;

$yellow-100: #fff3cd;
$yellow-400: #ffcd39;
$yellow-700: #997404;
$yellow-800: #664d03;

$cyan-100: #cff4fc;
$cyan-700: #087990;

$purple-100: #e2d9f3;
$purple-700: #432874;
