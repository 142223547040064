@import "@shared/ui/_variables.scss";

.check-email {
  .check-text-block {
    margin-bottom: 24px;
  }
  .check-email__label {
    color: $gray_secondary_900;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: block;
    margin-bottom: 6px;
  }
  .error-validation-message-email {
    bottom: 6px;
    display: block;
  }
}
