@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.switcher-custom-_wrapper {
  background-color: $gray_secondary_200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 4px;
  margin-right: 16px;

  .switcher-custom__item {
    min-width: 43px;
    height: 30px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 13px;
  }
  .switcher-active {
    background-color: $white;
  }
}
