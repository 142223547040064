@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.groups-price-rounding__wrapper {
  @include groups-wrapper();
}

.groups-price-rounding__title {
  @include groups-title();
}

.groups-price-rounding__subtitle {
  @include groups-subtitle();
}

.groups-price-rounding-content {
  .groups-price-rounding-block {
    min-height: 40px;
  }
  .groups-price-rounding__text {
    color: $gray_secondary_900;
    font-family: "Inter-600";
  }
  .input-price-rounding-w {
    width: 100%;
    text-align: center;
    color: $gray_secondary_900;
    font-family: "Inter-400";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .groups-price-rounding__placeholder-error-decimal {
    position: absolute;
    bottom: -16px;
    left: 25px;
    color: $gray_secondary_600;
    font-family: "Inter-300";
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }
  .groups-price-rounding__placeholder-error-integer {
    position: absolute;
    bottom: -16px;
    left: 39px;
    color: $gray_secondary_600;
    font-family: "Inter-300";
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }
  .groups-price-rounding-direction {
    margin: 44px 0px;
    font-family: "Inter-400";
    font-size: 16px;
    font-weight: 400;
    color: $gray_secondary_900;
    line-height: 28px;
  }
  .rounding-alert {
    color: $gray_secondary_800;
    font-family: "Inter-400";
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
  }
  .rounding-alert-example-italic {
    font-style: italic;
    color: $gray_secondary_800;
    font-size: 14px;
  }
  .rounding-alert-example-number {
    font-weight: 700;
    font-style: italic;
    color: $gray_secondary_800;
    font-size: 14px;
  }

  .rounding-alert-block {
    width: 536px;
  }
  .error-validation-message {
    position: absolute;
    margin-top: -9px;
    color: $danger_500;
    width: max-content;
    display: block;
    font-family: "Inter-300";
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }
}
