@import "@shared/ui/_variables.scss";

.modal-remove-account {
  &-title {
    padding: 24px;
    color: $gray_secondary_900;
    font-size: 21px;
    font-weight: 600;
    line-height: 25.2px;
  }

  &-content {
    padding: 24px;
  }
  &-footer {
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .divider-del-acc {
    margin-bottom: 24px;
  }
}
.del-user-success-block {
  padding: 24px;
  text-align: center;
  &__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .svg-block {
    margin-bottom: 24px;
  }
}
.del-user-button-block {
  display: flex;
  padding: 0px 24px 24px 24px;
  align-items: center;
  justify-content: center;
}
