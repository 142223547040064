@import "@shared/ui/_variables.scss";

.group-page {
  .group-page-title {
    margin: 16px 24px;
  }
  h1 {
    font-family: "Inter-600" !important;
  }
  .group-page-tabs {
    margin: 16px 24px 0px 24px;
    .ant-tabs-tab {
      div {
        h4 {
          color: $gray_secondary_600;
          font-family: "Inter-300" !important;
          font-weight: 600;
        }
      }
    }

    .ant-tabs-tab-active {
      div {
        h4 {
          color: $gray_secondary_900;
          font-family: "Inter-300" !important;
          font-weight: 600;
        }
      }
    }
  }
  .edit-title__actions {
    display: flex;
  }
  .dots-title__btn {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid $gray_secondary_300;
    cursor: pointer;
  }
  .tab-title-page {
    width: 80px;
    text-align: center;
  }

  .warning__btn {
    background-color: $yellow-100;
    color: $yellow-700;
    pointer-events: none;
  }
  .warning__btn:hover {
    background-color: $yellow-100 !important;
    color: $yellow-700 !important;
  }
  .applying-prices-block {
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 13;
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(2.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    &__content {
      width: 320px;
      height: 201px;
      border-radius: 12px;
      border: 1px solid $gray_secondary_300;
      background: $white;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      .loader {
        span {
          width: 48px;
          height: 48px;
        }
      }
      .block__text {
        height: 81px;
        &__title {
          margin-bottom: 8px;
        }
        &__subtitle {
          color: var(--gray-primary-900-text-black, #212529);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}
