@import "@shared/ui/_variables.scss";

.verify-form_wrapper {
  text-align: center;
  width: 300px;
  position: relative;

  .verify__subtext {
    color: $gray_secondary_900;
    color: #212529;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    text-align: start;
    margin-bottom: 6px;
  }

  .verify-form__text {
    color: $gray_secondary_600;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  button.resend__btn {
    background: transparent;
    span {
      background: transparent;
      color: $gray_secondary_900;
      font-size: 16px;
      line-height: 24px;
      font-family: "Inter-200";
    }

    & > div {
      display: none;
    }

    &:hover {
      background: transparent !important;
    }
  }

  .error-verify {
    color: $danger_500;
    text-align: start;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    display: flex;
    align-items: end;
    margin-top: 5px;
    height: 13px;
  }
}
.verify-code__container-error {
  input {
    border: 1px solid $danger_500 !important;
    box-shadow: 0 0 0 3px $danger_200 !important;
    &:hover {
      border: 1px solid $danger_500 !important;
      box-shadow: 0 0 0 3px $danger_200 !important;
    }
    &:active {
      border: 1px solid $danger_500 !important;
      box-shadow: 0 0 0 3px $danger_200 !important;
    }
    &:focus {
      border: 1px solid $danger_500 !important;
      box-shadow: 0 0 0 3px $danger_200 !important;
    }
    &:focus-within {
      border: 1px solid $danger_500 !important;
      box-shadow: 0 0 0 3px $danger_200 !important;
    }
  }
}
.verify-form__timer {
  color: $gray_secondary_600;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.verify-form__resend {
  display: inline-block;
  div {
    display: inline-block;
  }
  .clicked {
    color: $gray_secondary_600;
    position: absolute;
    bottom: -5px;
    left: 67px;
  }
  .clicked-timer {
    position: absolute;
    bottom: -9px;
    left: 174px;
  }
}

.custom-style {
  cursor: pointer;
}
