@import "@shared/ui/_variables.scss";

.users-modal-invite {
  width: 500px !important;

  .modal__add-user {
    cursor: pointer;
    padding: 0px 0px 24px 0px;
    display: flex;
    align-items: center;
    width: max-content;
    svg {
      margin-right: 4px;
    }
  }
  .modal__invite-user-items {
    padding: 24px 24px 0px 24px;
    max-height: 600px;
    overflow: auto;
  }
}
.users-modal-invite__wrapper {
  padding: 24px;
}

.modal__invite-user-content {
  .modal__invite-user-form-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .modal__invite-user-email-block {
      width: 230px;
      height: 82px;
      .user-label {
        color: $gray_secondary_900;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .user-input {
        margin-top: 6px;
        input {
          padding: 9px 16px;
          color: $gray_secondary_900;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          width: 100%;
        }
        .validation-error {
          color: $danger_500;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
          display: inline-block;
          width: max-content;
        }
      }
    }
    .modal__invite-user-role-block {
      width: 180px;
      height: 82px;
      .role-label {
        color: $gray_secondary_900;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .role-select {
        margin-top: 6px;
        display: flex;
        align-items: center;
        &-trash {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-left: 12px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
.role-select-item {
  &:hover {
    background-color: $gray_secondary_100;
    cursor: pointer;
  }
  padding: 8px 24px;
  &-title {
    color: $gray_secondary_900;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  &-description {
    color: $gray_secondary_600;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
}
.role-select-item-active {
  background-color: $gray_secondary_200;
  padding: 8px 24px;
  &-title {
    color: $gray_secondary_900;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  &-description {
    color: $gray_secondary_600;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
}
