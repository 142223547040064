@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.groups-widget-empty__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.column__content {
  .sorted__box {
    margin-left: 4px;
  }
}

.group-name__link {
  a {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: $gray_secondary_900;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
  }
}

.group-name__badge {
  width: fit-content;
  padding: 4px 8px;
  border-radius: 12px;
  text-transform: capitalize;
}

.table-dots {
  .ant-btn {
    width: 38px;
  }
}

.reprice-badge__table,
.total-items__table {
  display: flex;
  justify-content: flex-end;
}

.gropus__table {
  td {
    color: $gray_secondary_900;
    h5 {
      font-family: "Inter-300";
      font-weight: 400;
    }
    .no-date {
      color: $gray_secondary_600;
      text-align: start;
    }
  }
  tr {
    cursor: pointer;
  }
}
