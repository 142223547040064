@import "@shared/ui/_variables.scss";

.modal__body-competition {
  padding: 0px 24px;
  .no-data {
    text-align: start;
    padding: 11px 0px;
    border-bottom: 1px solid $gray_secondary_200;
  }
  .arrow__border-top {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
  }

  .arrow__border-bottom {
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul {
    margin-left: 22px;
  }

  ul li {
    list-style-type: none;
    padding: 0;
    display: flex;
    margin-left: 8px;
  }

  ul li a {
    color: $blue-500;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: $blue-500;
    margin-right: 3px;
  }

  .table-title {
    display: flex;
    padding: 16px;
    font-family: "Inter-600" !important;
  }

  .table-item-title a {
    color: $blue-500;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: $blue-500;
    margin-right: 3px;
  }

  .expanded__list {
    line-height: 21px;
    .icon {
      margin-left: 8px;
    }
  }

  .expanded__list:not(:last-child) {
    margin-bottom: 16px;
  }

  .expanded__list-count {
    color: $gray_secondary_900;
  }

  .table-item-traffic {
    display: flex;
    justify-content: start;
    flex-direction: column;
  }

  .modal__search-competitor-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    input {
      width: 240px;
    }
  }

  .expanded__list-item {
    max-width: 660px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  .expanded__list-container {
    padding: 8px 0px;
  }
  .selected__container {
    border-top: 1px solid $gray_secondary_200;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    height: 86px;
    position: fixed;
  }
  .modal__table {
    height: 520px;
    overflow: auto;
    position: relative;
    tbody {
      border-bottom: 1px solid $gray_secondary_200;
      tr {
        border-top: 1px solid $gray_secondary_200;
      }
    }
  }
  .no__data {
    height: inherit;
  }
  .disable-padding {
    padding: 0px !important;
  }
}
.expanded__list-link {
  width: 100%;
}

.table__checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  padding: 0px 16px;
}
.table__arrow {
  padding: 0px !important;
}
.no-data-text {
  width: 100%;
  height: 389px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 14px;
  }
}
.no-data-link {
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none !important;

    color: $blue-500 !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150% !important;
    text-decoration-line: underline !important;
    text-decoration-style: solid !important;
    text-decoration-skip-ink: auto !important;
    text-decoration-thickness: auto !important;
    text-underline-offset: auto !important;
    text-underline-position: from-font !important;
  }
  text-decoration: none !important;

  color: $blue-500 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  text-decoration-line: underline !important;
  text-decoration-style: solid !important;
  text-decoration-skip-ink: auto !important;
  text-decoration-thickness: auto !important;
  text-underline-offset: auto !important;
  text-underline-position: from-font !important;
}
.empty-data {
  margin-top: 30px;
}

.support-text {
  height: 54px;
  border-top: 1px solid $gray_secondary_200;
  border-bottom: 1px solid $gray_secondary_200;
  display: flex;
  align-items: center;
  padding-left: 10px;
  span {
    font-size: 14px;
  }
}
.disable-default-styles {
  border: none !important;
  height: 40px !important;
}
.check-item-product {
  margin-right: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

///
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  content: "";
  position: absolute;
  width: 100%;
  border-radius: 4px;
  background-color: #212529;
  height: inherit;
}
.ant-checkbox-indeterminate::after {
  width: 10px !important;
  height: 2px !important;
  display: block !important;
  position: absolute !important;
  background-color: $white !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  content: "" !important;
}
