@import "@shared/ui/_variables.scss";

.signup-verify__container {
  text-align: center;
  width: 300px;

  .verify__btn {
    margin-top: 24px;
  }

  .signup-verify__title {
    color: $gray_default_1;
    margin-bottom: 44px;
    text-align: center;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .signup-verify__subtitle {
    text-align: center;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 38.4px !important;
    color: #212529;
    margin-bottom: 8px;
  }

  h4.signup-verify__text {
    color: $gray_secondary_900;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  h4.signup-verify__subtext {
    color: $gray_secondary_900;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  h4.signup-verify__subtext {
    font-family: "Inter-400";
  }

  h4.verify__subtext-margin {
    margin-bottom: 24px;
  }

  h4.verify__subtext-styling {
    color: $gray_secondary_600;
    margin-top: 24px;
  }

  .verify-code__container {
    display: flex;

    .verify-code__wrapper {
      margin-right: 8px;
    }

    .verify-code__wrapper:last-child {
      margin-right: 0;
    }
  }
}
