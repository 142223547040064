@import "@shared/ui/_variables.scss";

.modal__monitoring-content {
  padding: 24px;
  .modal__monitoring-text {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
.modal-monitoring-title {
  padding: 24px;
  color: $gray_secondary_900;
  font-size: 21px;
  font-weight: 600;
  line-height: 25.2px;
}

.modal__monitoring-footer {
  padding: 24px;
}
