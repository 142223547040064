@import "@shared/ui/_variables.scss";

.main-forgot-password {
  margin-top: 14%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__content {
    width: 300px;
  }
  &__logo {
    color: $gray_default_1;
    text-align: center;
    font-size: 32px;
    line-height: normal;
    margin-bottom: 44px;
  }
  .error-validation-message-input {
    left: 2px;
    bottom: 6px;
  }
  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray_secondary_900;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    span {
      margin-left: 4px;
    }
  }

  .title {
    color: $gray_secondary_900;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
  }
  .text {
    color: $gray_secondary_900;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-step {
    margin: 24px 0px;
  }
  .label {
    color: $gray_secondary_900;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: block;
    margin-bottom: 6px;
  }
  .main-forgot-password__back-text {
    color: $gray_secondary_900;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    a {
      &:hover {
        text-decoration: underline;
        text-underline-offset: 1px;
      }
    }
  }
}
