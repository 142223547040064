@import "@shared/ui/_variables.scss";

.aside-popover__notifications {
  .ant-popover-content {
    bottom: 24px;
    left: 7px;
  }
}

.notification-aside__container {
  width: 600px;

  .notification-aside__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;

    .default__btn {
      width: 155px;
    }
  }
  
  .notification-aside__title {
    font-size: 24px!important;
    line-height: 29px!important;
    font-weight: 500;
    margin-bottom: 0;
    font-family: 'Inter-500';
  }

  .notification-aside__suptitle {
    color: $gray_secondary_600;
    margin: 0!important;
    margin-top: 4px!important;
    font-size: 14px!important;
    line-height: 21px!important;
    font-weight: 400;
  }

  .notification-aside__body {
    max-height: 388px;
    overflow: scroll;
  }
}

.notification-aside__empty {
  padding: 24px;
  text-align: center;
  font-size: 14px!important;
  line-height: 21px!important;
  font-weight: 400;
  font-family: 'Inter-300';
}