@import "@shared/ui/_variables.scss";

.spiner {
  width: 15px;
  height: 15px;
  border: 1.5px solid $gray_secondary_600;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
