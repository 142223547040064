@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.users-modal-del__wrapper {
  padding: 24px;
}

.modal__remove-user-content {
  padding: 24px;
}
