@import "./variables";
@import "reset.scss";
@import "app.scss";

/*100*/
@font-face {
  font-family: "Inter-100";
  font-weight: 100;
  src: local("Inter-Thin"), url(./fonts/Inter-Thin.ttf) format("truetype");
}

/*200*/
@font-face {
  font-family: "Inter-200";
  font-weight: 200;
  src: local("Inter-ExtraLight"),
    url(./fonts/Inter-ExtraLight.ttf) format("truetype");
}

/*300*/
@font-face {
  font-family: "Inter-300";
  font-weight: 300;
  src: local("Inter-Light"), url(./fonts/Inter-Light.ttf) format("truetype");
}

/*400*/
@font-face {
  font-family: "Inter-400";
  font-weight: 400;
  src: local("Inter-Light"), url(./fonts/Inter-Regular.ttf) format("truetype");
}

/*500*/
@font-face {
  font-family: "Inter-500";
  font-weight: 500;
  src: local("Inter-Medium"), url(./fonts/Inter-Medium.ttf) format("truetype");
}

/*600*/
@font-face {
  font-family: "Inter-600";
  font-weight: 600;
  src: local("Inter-SemiBold"),
    url(./fonts/Inter-SemiBold.ttf) format("truetype");
}

/*700*/
@font-face {
  font-family: "Inter-700";
  font-weight: 700;
  src: local("Inter-Bold"), url(./fonts/Inter-Bold.ttf) format("truetype");
}

/*800*/
@font-face {
  font-family: "Inter-800";
  font-weight: 800;
  src: local("Inter-ExtraBold"),
    url(./fonts/Inter-ExtraBold.ttf) format("truetype");
}

/*900*/
@font-face {
  font-family: "Inter-900";
  font-weight: 900;
  src: local("Gilroy-Light"), url(./fonts/Inter-Black.ttf) format("truetype");
}

h1 {
  font-size: 24px !important;
  line-height: 24px !important;
}

h2 {
  font-size: 21px !important;
  line-height: 25px !important;
}

h3 {
  font-size: 18px !important;
  line-height: 21px !important;
}

h4 {
  font-size: 16px !important;
  line-height: 24px !important;
}

h5 {
  font-size: 14px !important;
  line-height: 21px !important;
}

h6 {
  font-size: 13px !important;
  line-height: 17px !important;
}
