@import "@shared/ui/_variables.scss";

.form_btn {
  width: 100%;
  box-shadow: none;
  outline: none !important;

  .ant-btn-icon {
    display: flex;
  }
}

.default__btn {
  border-color: $gray_secondary_300;
  box-shadow: none;
  color: $gray_secondary_900 !important;

  &:hover {
    border-color: $gray_secondary_300 !important;
    background-color: $gray_secondary_100 !important;
  }
}

button.gray-primary-900 {
  background: $gray_secondary_900;

  // &:hover{
  //   background: $gray_secondary_900!important;
  // }
}

button.red-600 {
  background: $danger_600;

  &:hover {
    background: $danger_600 !important;
  }
}

.red-primary-300 {
  border-color: $danger_300;

  span {
    color: $danger_500;
  }

  &:hover {
    background: transparent !important;
    border-color: $danger_300 !important;
  }
}
