@import "@shared/ui/_variables.scss";

.login__container {
  text-align: center;
  width: 300px;

  .login__title {
    margin-bottom: 44px;
    color: $gray_default_1;

    text-align: center;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .login__subtitle {
    font-size: 32px;
    color: $gray_secondary_900;
    margin-bottom: 8px;
  }

  h4.login__text {
    color: $gray_secondary_900;
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .login-terms__text {
    display: flex;
    align-items: center;
    margin-top: 24px;
    justify-content: center;
    text-align: center;
    div {
      color: $gray_secondary_600;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    a {
      color: $gray_secondary_900;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }
}
