@import "@shared/ui/_variables.scss";

.status__container {
  border-radius: 12px;
  display: inline-block;

  h5 {
    font-size: 12px !important;
    line-height: 18px !important;
    font-family: "Inter-400";
    font-weight: 400;
    text-align: center;
    padding: 4px 8px;
  }
}

.status-coming {
  background: $gray_secondary_300;

  h5 {
    color: $gray_secondary_700;
  }
}

.status-reprice {
  background: $blue-100;

  h5 {
    color: $blue-700;
  }
}

.status-failed {
  background: $danger_100;

  h5 {
    color: $danger_700;
  }
}

.status-inprogres {
  background: $yellow-100;

  h5 {
    color: $yellow-700;
  }
}

.status-completed {
  background: $green-100;

  h5 {
    color: $green-700;
  }
}
