@import "@shared/ui/_variables.scss";

.signup__container {
  text-align: center;
  width: 300px;

  .signup__title {
    color: $gray_default_1;
    text-align: center;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 44px;
  }

  .signup__subtitle {
    color: $gray_secondary_900;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 38.4px;
    margin-bottom: 8px;
  }

  h4.signup__text {
    color: $gray_secondary_900;
    color: var(--gray-primary-900-text-black, #212529);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .signup-terms__text {
    color: $gray_secondary_600;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    a {
      color: $gray_default_5;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }

    &:last-child {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }

  h4.signup-account__text {
    margin-top: 0;
  }
}
