@import "@shared/ui/_variables.scss";

.item-info__container {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 690px;
  border-left: 1px solid $gray_secondary_300;
  background: $white;
  z-index: 10;
  height: 100vh;

  .ant-tabs-tab {
    width: 315px !important;
    display: flex;
    justify-content: center;
    margin: 0px !important;
  }
  .item-info-close__icon {
    cursor: pointer;
  }
  .ant-tabs {
    width: 100%;
  }
  .tab-title {
    width: 110px;
    text-align: center;
  }
  .item-info__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 94%;

    a {
      color: $blue-500 !important;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      text-decoration: underline;
      text-decoration-color: $blue-500;
      text-decoration-thickness: 1px;
      text-underline-offset: 6px;
    }
  }
  .item-info__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .item-info__header-container {
    padding: 24px;
    border-bottom: 1px solid $gray_secondary_200;
    .item-info__header-info {
      display: flex;
      margin: 8px;

      .item-info__header-info-key {
        flex: 3;
        color: $gray_secondary_600;
        font-size: 14px;
        line-height: 20px;
      }
      .item-info__header-info-value {
        flex: 9;
        font-size: 14px;
        line-height: 20px;
        color: $gray_secondary_900;
      }
    }
  }
  .ant-tabs-tab-btn {
    outline: none !important;
  }
  .ant-tabs-tab {
    div {
      h4 {
        color: $gray_secondary_600;
        font-weight: 600;
      }
    }
  }

  .ant-tabs-tab-active {
    div {
      h4 {
        color: $gray_secondary_900;
        font-weight: 600;
      }
    }
  }
  .beta-badge {
    background-color: $gray_secondary_300;
    color: $gray_secondary_700;
  }
}

.item-tab-scroll {
  overflow: auto;
  height: calc(100vh - 230px);
}
