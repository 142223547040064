.groups__form-title {
  font-family: "Inter-500" !important;
  padding: 24px;
}

.groups__form-content {
  padding: 24px;
}

.groups__form-suptitle {
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: "Inter-300" !important;
  font-weight: 400 !important;
  padding: 24px;
}

.reject__btn {
  width: 128px !important;
}
