@import "@shared/ui/_variables.scss";

.aside-dropdown {
  height: 0;
}

.aside-dropdown__btn {
  padding: 0;
  height: auto;
  width: auto;
  outline: none !important;
  border: none !important;
  background: transparent;
  box-shadow: none;

  &:hover {
    background: transparent !important;
  }

  .ant-wave {
    color: $gray_secondary_500;
  }
}
