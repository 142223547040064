@import "@shared/ui/_variables.scss";

.item-market {
  height: 100%;
  .item-market-history {
    margin: 20px 24px 20px 24px;
  }
  .market-competitors {
    padding: 24px;
  }

  .market-select-competitors,
  .market-select-period {
    display: flex;
    align-items: center;
    .market-select-competitors-title,
    .market-select-period-title {
      margin-right: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .link-to-new {
      cursor: pointer;
    }
  }
  .item-market-info-badge {
    margin: 20px 24px 30px 24px;
    border-radius: 8px;
    border: 1px solid $gray_secondary_300;
    background: $gray_secondary_100;
    display: flex;
    height: 85px;
    justify-content: space-between;
    > div {
      padding: 16px;
      flex: 4;
    }
    .title {
      color: $gray_secondary_600;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 8px;
    }
    .text {
      color: $gray_secondary_900;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: 25.2px;
    }
  }
  .time-ago {
    color: $gray_secondary_600;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
  .alert-custom-td {
    height: 35px;
    cursor: inherit;
  }
  .remove-competitor-item {
    border-bottom: 1px solid $yellow-800;
  }
  .item-market-graph {
    position: relative;
  }
  .market-competitors-table {
    .table-competitors {
      border-bottom: 1px solid $gray_secondary_200;
    }
  }
  .badge-custom {
    background-color: $gray_secondary_200;
    color: $gray_secondary_700;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }
  .deliveryColumn {
    text-align: start;
  }
}
.market-title {
  font-family: "Inter-600";
  color: $gray_secondary_900;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin-left: 8px;
  }
}
.market-title-competitors {
  color: $gray_secondary_900;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  span {
    margin-left: 8px;
  }
}
.colorCircle {
  min-width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 4px;
}
.market-select-competitors-block {
  min-width: 172px;
}
.name-block-with-color {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.item-market-graph-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 270px;
}
.name-competitor {
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.toggle-competitors-list {
  cursor: pointer;
  color: $gray_secondary_900;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-top: 24px;
  text-align: center;
  height: 60px;
}
