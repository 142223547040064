@import "@shared/ui/_variables.scss";

.badge__wraper {
  width: 24px;
  height: 24px;
  background: $gray_secondary_300;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  h5 {
    color: $gray_secondary_700;
    font-size: 12px !important;
    font-family: "Inter-400" !important;
    font-weight: 400 !important;
    margin-top: 0 !important;
  }

  &.gray-600 {
    background: $gray_secondary_600;

    h5 {
      color: white;
    }
  }
}
