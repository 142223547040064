@import "@shared/ui/_variables.scss";

.loader-block-main {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .spiner {
    border: 1.5px solid $gray_secondary_600 !important;
    border-bottom-color: transparent !important;
  }
}
