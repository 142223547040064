@import "@shared/ui/_variables.scss";

.notification__wrapper {
  width: max-content;
  .ant-notification-notice-message {
    margin: 0 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: $gray_secondary_800 !important;
  }
}
.ant-notification-notice-wrapper {
  width: max-content;
}
.notification-success,
.notification-default,
.notification-error,
.notification-info,
.notification-warning {
  padding: 16px !important;
  border-radius: 6px;
  width: auto !important;
  min-width: 366px;
}

.notification-success {
  background-color: $green-100;
  border: 1px solid $green-400;
  color: $green-800;
}

.notification-default {
  color: $gray_secondary_800;
  border: 1px solid $gray_secondary_400;
  background: $gray_secondary_200;
}
.notification-error {
  color: $danger_800;
  border: 1px solid $danger_400;
  background: $danger_100;
}

.ant-notification-notice-icon {
  display: contents;
}
.ant-notification-notice-with-icon {
  display: flex;
  svg {
    margin-right: 12px;
  }
}
