@import "@shared/ui/_variables.scss";

.settings-block {
  padding: 32px;
  .settings-page-item {
    padding: 24px;
    border: 1px solid $gray_secondary_300;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 462px;
    height: 101px;
    margin-bottom: 32px;
  }
  .label {
    color: $gray_secondary_900;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.2px;
  }
  .description {
    color: $gray_secondary_900;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .active {
    cursor: pointer;
  }
}
