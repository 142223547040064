@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.groups-policy__wrapper {
  @include groups-wrapper();
}

.groups-policy__title {
  @include groups-title();
}

.groups-policy__subtitle {
  @include groups-subtitle();
}


.groups-policy__text {
  color: $gray_default_5;
  font-family: "Inter-300" !important;
}

.groups-switch {
  margin-top: 16px;
  display: flex;
  align-items: center;

  h5 {
    font-family: "Inter-300" !important;
    color: $gray_secondary_900;
    margin-left: 8px;
    margin-right: 12px;
    cursor: pointer;
  }
}

