@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.items-apply-new-prices__wrapper {
  @include groups-wrapper();
}

.items-apply-new-prices__title {
  @include groups-title();
}

.items-apply-new-prices__subtitle {
  @include groups-subtitle();
  margin-bottom: 0;
}

.items-apply-new-prices-body {
  padding: 0px 0px 0px 23px;
  overflow: auto;
  max-height: 600px;
  width: fit-content;
  .custom-padding {
    padding: 0px 8px !important;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .item__subtitle {
    font-size: 12px !important;
    line-height: 18px !important;
    font-weight: 400 !important;
    font-family: "Inter-300" !important;
    color: $gray_secondary_600 !important;
    text-align: left;
  }
  .item__title {
    @include product-title($blue-700!important, 500px!important);
  }
}
