@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";
.group-items {
  .group-page-alert {
    margin-bottom: 24px;
    margin-top: 8px;
  }
}

.group-items__filters {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.group-items-select__container {
  display: flex;
  align-items: center;
}
.group-items-select__title {
  margin-right: 8px;
  color: $gray_secondary_900 !important;
  font-family: "Inter-400";
  font-weight: 400;
}

.group-items__table {
  overflow: auto;

  .relu__table tbody td {
    padding: 0px !important;
  }
  .tr-selected {
    background-color: $gray_secondary_200 !important;
  }
}

.group-items__table::-webkit-scrollbar:horizontal {
  height: 0;
}

.clear-table-overflow {
  overflow: hidden;
}

.product__new-value--positive {
  color: $green-500;
  font-size: 12px;
  line-height: 12px;
}
.product__new-value--negative {
  color: $danger_500;
  font-size: 12px;
  line-height: 12px;
}

.product__current-value {
  color: $gray_secondary_900;
  font-size: 14px;
  line-height: 21px;
}

.group_product__title {
  @include product-title($blue-700!important, 90%!important, 348px);
}

.sticky-table__th--left-image {
  position: sticky !important;
  left: 380px !important;
  z-index: 1;
  background: $gray_secondary_100;
  box-shadow: inset -3px 0 2px -1px rgba(0, 0, 0, 0.1);
}

.sticky-table__body--left-image {
  position: sticky !important;
  left: 380px !important;
  z-index: 1;
  background: $white;
  box-shadow: inset -3px 0 2px -1px rgba(0, 0, 0, 0.1);
}

.sticky-table__th--left-name {
  position: sticky !important;
  left: 0px !important;
  z-index: 1;
  background: $gray_secondary_100;
}
.sticky-table__body--left-name {
  position: sticky !important;
  left: 0px !important;
  z-index: 1;
  background: $white;
}

.custom-scrollbar-block {
  border-radius: 3px;
  border: 1px solid transparent;
  height: 8px;
  position: absolute;
  bottom: 64px;
  left: 456px;
  display: flex;
  align-items: center;
}
.custom-scrollbar {
  background-color: $gray_secondary_400;
  height: 7px;
  cursor: pointer;
  position: absolute;
  border-radius: 3px;
  padding: 0px 3px;
  left: 0px;
  z-index: 1;
}

.active-row-sticky {
  background-color: $gray_secondary_200;
}

// .group-items__table tr:hover {
//   background-color: $gray_secondary_100 !important;
// }

.custom-height-group-items {
  height: 53px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
}
.custom-height-group-items-name {
  height: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.multiselect_brands {
  min-width: 161px !important;
}
.group-items__no-results {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray_secondary_900;
}
.image-block-table {
  display: flex;
  justify-content: center;
  align-items: center;
}
.default-padding-blocks-table {
  padding: 0px 16px;
}
.filter__new-price-ready {
  min-width: 198px;
  display: flex;
  justify-content: center;
}
