@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.groups-create__btn {
  width: 153px;
}

.groups-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 65px);
  .groups-empty__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 550px;
    flex-direction: column;
    text-align: center;
  }
  .groups-empty__title {
    @include groups-title();
    margin-bottom: 8px;
  }
  .groups-empty__subtitle {
    @include groups-subtitle();
    margin-bottom: 24px;
  }
}
.groups-loading {
  display: flex;
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
