@import "@shared/ui/_variables.scss";

.setpass-form_wrapper {
  .input-label__wrapper {
    margin-bottom: 24px;
  }

  .setpass__btn {
    margin-top: 24px;
  }
}

.signup-form_wrapper {
  position: relative;
  .btn__submit {
    margin-top: 24px;
  }
  .custom-height {
    height: 82px;
  }
  .error-validation-message {
    position: absolute;
    margin-top: -17px;
    color: $danger_500;
    width: max-content;
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }
  .ant-select-selector {
    // border-color: transparent !important;
  }
  .error-validation-message-role {
    position: absolute;
    color: $danger_500;
    width: max-content;
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    bottom: 48px;
  }
}
