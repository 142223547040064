@import "@shared/ui/_variables.scss";
/*************** POPOVERS ******************/
.aside-popover__parent {
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 5px;
}

.aside-popover__profile {
  .ant-popover-content {
    bottom: 24px;
    left: 7px;
  }
}

.popover-profile__content {
  padding: 8px 0;

  .popover-profile__info {
    padding: 12px 24px 0;

    .popover-profile__name {
      font-family: "Inter-500";
      font-weight: 500;
      margin: 0 !important;
      margin-right: 8px !important;
      color: $gray_secondary_900;
    }
    .admin__badge {
      background: $indigo-100;
      color: $indigo-700;
    }
    .viewer__badge {
      background: $gray_secondary_200;
      color: $gray_secondary_700;
    }
    .manager__badge {
      background: $blue-100;
      color: $blue-700;
    }
    .owner__badge {
      background: $green-100;
      color: $green-700;
    }
    .default__badge {
      background: $danger-100;
      color: $danger-700;
    }

    .popover-profile__role {
      font-weight: 400;
      margin: 0 !important;
      padding: 2px 8px;
      border-radius: 14px;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .popover-profile__email {
    font-family: "Inter-400" !important;
    font-weight: 400;
    padding: 0 24px 12px;
    margin: 0 !important;
    margin-top: 4px !important;
    color: $gray_secondary_600 !important;
  }

  .popover-content__item {
    font-family: "Inter-400" !important;
    color: $gray_secondary_900;
    font-weight: 400;
    padding: 0 24px;
    height: 38px;
    display: flex;
    align-items: center;
    margin: 0 !important;
    width: 100%;

    &:hover {
      cursor: pointer;
      background-color: $gray_secondary_200;
    }
  }

  .aside-popover__logout {
    &.aside-popover__logout {
      color: $danger_500;
    }
  }
}
