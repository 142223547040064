@import "@shared/ui/_variables.scss";

.signup-setpass__container {
  text-align: center;
  width: 300px;

  .signup-setpass__title {
    margin-bottom: 44px;
    color: $gray_default_1;
    text-align: center;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .signup-setpass__subtitle {
    text-align: center;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 38.4px !important;
    color: $gray_secondary_900;
    margin-bottom: 8px;
  }

  h4.signup-setpass__text {
    color: $gray_secondary_900;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
  }
}
