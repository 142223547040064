@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.groups-summary__wrapper {
  @include groups-wrapper();
}

.groups-summary__title {
  @include groups-title();
}

.groups-summary__subtitle {
  @include groups-subtitle();
}

.summary__overview {
  margin-top: 32px;
}

.summary__overview-title {
  font-family: "Inter-600" !important;
  font-weight: 600;
  margin-bottom: 12px;
}

.summary__overview-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.summary__overview-key {
  width: 110px;
  color: $gray_secondary_600;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.summary__overview-value {
  margin-left: 16px;
  font-family: "Inter-400" !important;
  font-size: 14px;
  line-height: 18px;
  color: $gray_secondary_900;
  position: relative;
  padding: 4px 8px;
  border-radius: 12px;
  max-width: 320px;

  &.competition {
    background: $cyan-100;
    color: $cyan-700;
  }

  &.elasticity {
    background: $purple-100;
    color: $purple-700;
  }
}

.summary-exceptions {
  width: 126px;
  position: relative;
  display: flex;
  align-items: center;
  font-family: "Inter-400" !important;
  font-size: 14px;
  line-height: 18px;
}

.summary-exception__img {
  transform: rotate(90deg);
  height: 21px;
  width: 21px;
  margin-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.exception__img-rotate {
    transform: rotate(270deg);
  }
}

.summary__overview-exception {
  margin-top: 8px;
  max-width: 705px;
  overflow: auto;
  max-height: 114px;
}

.summary__overview-exception-item {
  list-style: none;
  margin-bottom: 8px;

  .exception-item__desc {
    font-size: 14px;
    line-height: 21px;
    color: $gray_secondary_900;
    margin-right: 16px;
    position: relative;

    .exception-item__desc-cor {
      margin-right: 5px;
    }

    &:last-child {
      margin-right: 0;
    }

    &.point {
      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        background: $gray_secondary_600;
        border-radius: 50%;
        left: -9px;
        top: 7px;
      }
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.summary__overview {
  .group-summary__input-block {
    width: 300px;
    .group-summary__input {
      width: 100%;
      font-size: 16px;
      padding: 9px 16px;
    }
  }

  .error-validation-message {
    position: absolute;
    margin-top: 5px;
    color: $danger_500;
    width: max-content;
    display: block;
    font-family: "Inter-300";
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }
}
