@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.strategy__content-item-radio {
  width: 16px;
  height: 16px;
  appearance: none;
  border: 2px solid $gray_secondary_300;
  border-radius: 50%;
  cursor: pointer;
}

.strategy__content-item-radio:checked {
  border-color: $gray_secondary_900;
  border-width: 5px;
}

.strategy__content-item-radio::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.strategy__content-item-radio:checked::before {
  background-color: transparent;
}
