@import "@shared/ui/_variables.scss";

.groups-bottom-bulk__container {
  padding: 10px 16px;
  border-top: 1px solid $gray_secondary_200;
  height: 74px;
  display: flex;
  align-items: center;

  .selected__block {
    display: flex;
    align-items: center;
  }

  .selected__count {
    font-family: "Inter-500" !important;
    font-weight: 600;
  }

  .selected__text {
    font-family: "Inter-300" !important;
    font-weight: 400;
    margin-left: 3px;
  }

  .groups-export__btn {
    width: 162px;
  }
}
