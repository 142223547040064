@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.groups-strategy__wrapper {
  @include groups-wrapper();
}

.groups-strategy__title {
  @include groups-title();
}

.groups-strategy__subtitle {
  @include groups-subtitle();
  margin-bottom: 40px;
}

.groups-strategy__content {
  display: flex;

  .strategy__content-item {
    cursor: pointer;
    outline: 1px solid $gray_secondary_300;
    width: 414px;
    height: 165px;
    border-radius: 8px;
    margin-right: 24px;
    padding: 24px 32px;
    position: relative;
  }
  .active {
    outline: 2px solid $gray_secondary_900;
  }
  .strategy__content-item-title {
    color: $gray_secondary_900;
    font-weight: 600;
    padding-bottom: 8px;
    width: 336px;
    font-family: "Inter-600";
  }
  .strategy__content-item-text {
    color: $gray_secondary_900;
    font-weight: 400;
    width: 336px;
    font-family: "Inter-400";
  }
  .strategy__item-radio {
    input {
      position: absolute;
      right: 32px;
      top: 24px;
    }
  }
}
.strategy-delete__btn {
  border-color: $danger_300;
  color: $danger_500;
}

.strategy-delete__btn {
  border-color: $danger_300;
  color: $gray_secondary_100;
  background-color: $danger_600;
}

.strategy-delete__btn:hover {
  border-color: $danger_500 !important;
  background-color: $danger_600 !important;
  color: $white !important;
}
.strategy__item-radio-loader {
  top: 24px;
  position: absolute;
  right: 32px;
}
.strategy__item-loader-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
