@import "@shared/ui/mixins.scss";
@import "@shared/ui/_variables.scss";

.groups-strategy-rule__wrapper {
  @include groups-wrapper();
  padding: 0px 24px 0px 24px;
  overflow: auto;
  height: -webkit-fill-available;
  .collapsed-block-competitors {
    position: fixed;
    top: 84px;
    background-color: #fff;
    min-height: 60px;
    width: -webkit-fill-available;
    z-index: 11;
    padding-top: 16px;
    padding-right: 31px;
  }
  .collapsed-block-competitors-open {
    position: fixed;
    top: 84px;
    background-color: $white;
    width: -webkit-fill-available;
    z-index: 10;
    padding-top: 16px;
    padding-right: 31px;
  }
}

.groups-strategy-rule__title {
  @include groups-title();
  margin-top: 16px;
}

.groups-strategy-rule__subtitle {
  @include groups-subtitle();
}

.groups-strategy-rule__content {
  display: flex;
  flex-direction: column;
  .groups-strategy-rule__text {
    color: $gray_secondary_900;
    font-family: "Inter-200";
    margin-bottom: 32px;
  }
  .groups-strategy-rule__btn {
    width: 180px;
  }
  .groups-strategy-rule__radio {
    padding-top: 16px;
    display: flex;
    align-items: center;
    height: 40px;
  }
  .groups-strategy-rule__radio-text {
    padding-right: 16px;
    cursor: pointer;
  }
  .groups-strategy-rule__radio-icon {
    padding-right: 6px;
  }
  .groups-strategy-rule__input {
    width: 72px;
  }
  .groups-strategy-rules__wrapper {
    display: flex;
    align-items: center;
    & > div {
      margin-right: 15px;
    }
  }
  .groups-strategy-rule__alert {
    padding: 16px;
    margin-top: 24px;
  }
  .groups-strategy-rule__info-table,
  .groups-strategy-rule__info-table-collapsed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    align-items: center;
    width: 100%;
    margin-top: 24px;
  }
  .groups-strategy-rule__info-table-collapsed {
    border-top: 1px solid $gray_secondary_200;
  }
  .groups-strategy-rule__btn {
    width: 158px;
  }
  h4 {
    font-weight: 600;
    font-family: "Inter-600";
  }
}
.anchor-block {
  position: relative;
  top: 43px;
  left: 0;
}
.table-header-custom-block {
  margin-top: 24px;
}

.groups-strategy-rule__table {
  thead {
    position: relative !important;
  }
  .arrow__border-top {
    transform: rotate(90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .arrow__border-bottom {
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ul {
    margin-left: 22px;
  }
  ul li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  ul li a {
    color: $blue-500;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: $blue-500;
    margin-right: 3px;
  }
  .table-title {
    display: flex;
    padding: 16px;
    font-family: "Inter-600" !important;
  }
  .table-item-title a {
    color: $blue-500;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: $blue-500;
    margin-right: 3px;
  }
  .expanded__list {
    line-height: 21px;
    .icon {
      margin-left: 8px;
    }
  }

  .expanded__list:not(:last-child) {
    margin-bottom: 16px;
  }
  .expanded__list-count {
    color: $gray_secondary_900;
  }
  .table-item-traffic {
    display: flex;
    justify-content: end;
    flex-direction: column;
  }
  .expanded__list-item {
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
    // // for moz browser
    // display: -moz-box;
    // display: box;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: normal;
    // max-height: 3em;
    // line-height: 1.5em;
    max-width: 860px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  .expanded__list-container {
    padding: 8px 0px;
  }
  .selected__container {
    border-top: 1px solid $gray_secondary_200;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    height: 60px;
  }
}
.groups-strategy-rule__alert-error {
  margin-bottom: 24px;
}
.groups-strategy-rule__alert-warning {
  margin-top: 24px;
}
.groups-strategy-rule__footer {
  padding-left: 12px;
  position: sticky;
  bottom: 0px;
  background-color: $white;
}
.collapsed-block-competitors__wrapper {
  width: -webkit-fill-available;
}
.custom-support-competition {
  border-bottom: 1px solid $danger-900;
  cursor: pointer;
}
.remove-unmatched-products {
  border-bottom: 1px solid $danger-900;
  cursor: pointer;
}
