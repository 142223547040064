@import "@shared/ui/_variables.scss";

.multiple-select {
  .ant-select-selection-placeholder {
    color: $gray_secondary_600 !important;
  }

  .ant-select-dropdown {
    // padding: 8px 16px;
    width: max-content !important;
    min-width: inherit;
    max-height: 300px;
    overflow: auto;
    .ant-checkbox-wrapper {
      padding: 5px;
      height: 35px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
    }
    .ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        background-color: $gray_secondary_900 !important;

        &::after {
          top: 50%;
          left: 50%;
          width: 15px;
          height: 9px;
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
          content: "";
          border-radius: 3px;
          display: block;
          width: 7px;
          height: 1.5px;
          background-color: $white;
        }
      }
    }
  }
  .added-select-item {
    padding: 0px 16px;
    border-bottom: 1px solid $gray_secondary_200;
  }
  .multiple-select__content {
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
  }
}

.ant-select-selection-item {
  background-color: transparent !important;
}

.ant-select-selection-overflow-item-rest {
  .ant-select-selection-item {
    background-color: transparent !important;
    position: absolute;
  }
}
.multiple-select__close {
  position: absolute;
  top: 7px;
  z-index: 9;
  left: 9px;
  display: flex;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
.multiple-select__count {
  padding-left: 24px;
  cursor: pointer;
}
