@import "@shared/ui/_variables.scss";

.loginform__wrapper {
  position: relative;
  .ant-alert {
    margin: 12px 0;
  }
  .valid-error {
    color: $danger_500;

    font-size: 12px !important;
  }
  .login-forgot__text {
    cursor: pointer;
    color: $gray_secondary_900;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    position: absolute;
    right: 0;
    top: 153px;
  }
}

.login-forgot__wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;
  margin-top: 4px;
}

.login-remember__wrapper {
  text-align: left;
  margin-bottom: 26px;
}
.remember-me__wrapper {
  display: flex;
  margin-top: 24px;
}
