@import "@shared/ui/_variables.scss";

.enter-new-password {
  margin-bottom: 24px;
  .enter-new-password-block {
    margin-bottom: 24px;
  }
  .new-password-validation-item {
    display: flex;
    align-items: center;
    color: $gray_secondary_600;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: start;
    margin-bottom: 4px;
    .error-validation {
      color: $danger_500;
    }
    span {
      margin-left: 8px;
    }
  }
}
