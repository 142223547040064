.groups-bulk__container {
  padding: 16px 0;
  display: flex;
  align-items: center;

  .input-custom__wrapper {
    input {
      width: 240px;
    }
  }
}