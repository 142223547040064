@import "./variables.scss";
@import "@shared/ui/mixins.scss";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Inter-400" !important;
}

.content__title {
  border-bottom: 1px solid $gray_secondary_300;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;
  position: relative;
  z-index: 2;

  h1 {
    margin-bottom: 0;
    margin-top: 0 !important;
  }
}

.ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
}

.content__title-left {
  flex: 1;
}

.content__container {
  max-width: 900px;
}

/************ FORM ************/
.form__item {
  text-align: left;

  label {
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 3px;
    font-weight: 400;
    color: $gray_secondary_900 !important;
  }

  input {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 3px;
    font-weight: 400;
    color: $gray_secondary_900;
  }

  .ant-typography-danger {
    font-size: 12px;
    line-height: 14px;
    margin-top: 3px;
  }
}

.form-item__mb24 {
  margin-bottom: 24px;
}

.form__promt {
  font-size: 12px !important;
  color: $gray_secondary_600 !important;
}

/************ DANGER ************/
.danger {
  color: $danger_500;
}

.ant-dropdown-menu-item-danger {
  &:hover {
    background-color: #0000000a !important;
  }
  span {
    color: $danger_500 !important;
  }
}

/************ ANT OVERRIDE ************/
.disabled,
.ant-menu-item-disabled,
.ant-dropdown-menu-item-disabled {
  cursor: not-allowed;
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

.disabled-no-effect {
  cursor: not-allowed;
  pointer-events: none;
}

.clicable {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

button.ant-btn {
  height: 38px;
  span {
    font-weight: 500;
    line-height: 24px;
  }
}

a.ant-typography,
a {
  color: $gray_secondary_900;

  &:hover {
    color: $gray_secondary_900;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: $gray_secondary_900;
  }
}

.ant-input,
.ant-select,
.ant-input-password {
  height: 38px;

  input {
    height: 100%;
  }

  &.gray-primary-900,
  &.ant-otp-input {
    &:focus {
      border-color: $gray_secondary_900 !important;
    }

    &:hover {
      border-color: $gray_secondary_900;
    }

    &:focus-within {
      border-color: $gray_secondary_900;
      box-shadow: 0 0 0 3px rgb(93 93 94 / 10%);
    }

    &.fill-eye {
      path:nth-last-child(-n + 2) {
        fill: $gray_default_10;
      }

      path:nth-last-child(n + 3) {
        fill: #fff;
      }
    }
  }

  &.ant-input-status-error {
    border-color: $danger_500 !important;
    box-shadow: 0 0 0 3px $danger_200 !important;
    &:focus {
      border-color: $danger_500 !important;
    }

    &:hover {
      border-color: $danger_500 !important;
    }

    &:focus-within {
      border-color: $danger_500 !important;
      box-shadow: 0 0 0 3px $danger_200 !important;
    }
  }

  &.ant-otp-input {
    width: 43px;
    height: 38px;
  }

  &.ant-select-outlined {
    width: 100%;
    border-radius: 7px;

    &:hover {
      .ant-select-selector {
        border-color: $gray_secondary_900 !important;
        box-shadow: 0 0 0 3px rgb(93 93 94 / 10%) !important;
      }
    }
    &:focus-within {
      .ant-select-selector {
        border-color: $gray_secondary_900 !important;
        box-shadow: 0 0 0 3px rgb(93 93 94 / 10%) !important;
      }
    }
  }
}

.ant-select-selection-wrap {
  align-self: center !important;
}

.ant-badge {
  sup {
    width: 24px !important;
    height: 24px !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 50% !important;
    box-shadow: none !important;

    span {
      font-size: 12px;
    }
  }

  &.badge-default {
    sup {
      background: $gray_secondary_800;
    }
  }
}

.ant-alert {
  .alert__content,
  a {
    font-weight: 400;
    font-size: 14px !important;
  }

  a {
    margin-right: 3px;
  }

  .ant-alert-message {
    font-size: 14px;
    line-height: 21px;
  }

  &.ant-alert-error {
    padding: 16px;

    .ant-alert-icon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      background-image: url("../../shared/ui/assets/alerts/error.svg");

      svg {
        display: none;
      }
    }

    text-align: left;
    color: $danger_800;
  }

  &.ant-alert-info {
    padding: 16px !important;

    .alert__content,
    a {
      color: $blue-800 !important;
    }
  }
}

.ant-popover-inner {
  padding: 0 !important;
}

.ant-divider-horizontal {
  background: $gray_secondary_300;
  margin: 0;
}

.ant-badge-status-dot {
  width: 8px !important;
  height: 8px !important;
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
  background: $white;
}

.relu__menu {
  .ant-dropdown-menu {
    padding: 8px 0;
  }

  .ant-dropdown-menu-item {
    padding: 8px 24px 8px 16px !important;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .ant-dropdown-menu-title-content {
    font-size: 16px;
    font-weight: 400;
    color: $gray_secondary_900;
    margin-left: 8px;
  }
}

/************ ANT MODAL ************/
.ant-modal-content {
  padding: 0px !important;
}

.modal__title {
  font-weight: 600 !important;
  padding: 24px;

  .groups-strategy__subtitle {
    @include groups-subtitle();
    margin-bottom: 0px;
  }
}

.modal-close__icon {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.modal__footer {
  padding: 24px;
}
.ant-modal-footer {
  margin: 0 !important;
}

/************ ANT OVERRIDE TABLE ************/
.table__margin {
  margin: 0 24px;
}

.table__padding {
  padding: 0 24px;
}

.table__scroll {
  max-height: calc(100vh - 217px);
  overflow: auto;
  padding-right: 20px;

  &.table__scroll-notification {
    max-height: calc(100vh - 300px);
  }
}

.wrapper-table {
  width: 100%;
  position: relative;
}
.relu__table {
  border-collapse: collapse;
  width: 100%;
  // overflow: auto;

  thead {
    position: sticky;
    top: 0;
    height: 54px;
    background: $gray_secondary_100 !important;
    border-bottom: 1px solid $gray_secondary_200;
    z-index: 9;

    th {
      position: relative;
      right: 0;
      left: 0;

      .ant-checkbox-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:last-child {
        .column__content {
          border-right: 0;
        }
      }
    }

    .column__content {
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 16px;
      left: 0;
      right: 0;
    }

    .sorted__box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  tbody {
    tr {
      height: 54px;
      border-bottom: 1px solid $gray_secondary_200;
      cursor: pointer;

      &.border-disabled {
        border-bottom: none;
      }
      &.tr-expanded {
        background: $gray_secondary_100;
      }
      &.tr-selected {
        background: $gray_secondary_200;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    td {
      padding: 0 16px;
      max-height: 54px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      vertical-align: middle;

      & > h5 {
        font-weight: 400;
      }
    }
  }

  .column__content {
    display: flex;
    align-items: center;

    h5 {
      margin-right: 4px;
      font-size: 13px !important;
      line-height: 17px !important;
      font-weight: 600 !important;
      text-align: left;
    }
  }
}

/************ FINISH OVERRIDE TABLE************/
.ant-select-dropdown {
  padding: 8px 0;
}

.ant-select-item,
.ant-select-selection-item {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px;
  border-radius: 0 !important;
}

.custom-arrow {
  .ant-select-arrow {
    transform: rotate(-90deg);
  }

  &.ant-select-open {
    .ant-select-arrow {
      transform: rotate(90deg);
    }
  }
}

.ant-select-item-option-active {
  background: $gray_secondary_200 !important;

  &:hover {
    background: $gray_secondary_100 !important;
  }
}

.ant-select-item-option-selected {
  background: $gray_secondary_200 !important;

  &:hover {
    background: $gray_secondary_200 !important;
  }
}

/************ SWITCH ************/
.ant-switch {
  min-width: 26px;
  height: 22px;

  .ant-switch-handle {
    height: 18px;
    width: 18px;
  }

  &.ant-switch-checked {
    .ant-switch-handle {
      inset-inline-start: calc(100% - 20px);
    }
  }
}

/************ GENERAL ************/
.pl {
  position: relative;
}

.ta-right {
  text-align: right;
}
.ta-left {
  text-align: left;
}

.ta-center {
  text-align: center;
}

.dc {
  display: contents;
}
.df {
  display: flex;
}

.ai-center {
  align-items: center;
}
.ai-end {
  align-items: end;
}

.jc-sb {
  justify-content: space-between;
}

.jc-se {
  justify-content: space-evenly;
}

.jc-fe {
  justify-content: flex-end;
}

.jc-c {
  justify-content: center;
}
.jc-start {
  justify-content: flex-start !important;
}

.poz-a {
  position: absolute;
}

.poz-r {
  position: relative;
}

.w-fc {
  width: fit-content;
}

/******** MARGINS **********/
.mr-4 {
  margin-right: 4px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-8 {
  margin-right: 8px;
}
.mr-12 {
  margin-right: 12px;
}
.mr-16 {
  margin-right: 16px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-4 {
  margin-top: 4px;
}
.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 6px;
}

.mb-8 {
  margin-bottom: 6px;
}

.ml-12 {
  margin-left: 12px;
}

.mr-12 {
  margin-right: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.m-24 {
  margin: 24px;
}

.ml-24 {
  margin-left: 24px;
}
.ml-36 {
  margin-left: 36px;
}
.ml-40 {
  margin-left: 40px;
}

.mr-24 {
  margin-right: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.ml-32 {
  margin-left: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.mt-24 {
  margin-top: 24px;
}
.mt-32 {
  margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}
.mb-36 {
  margin-bottom: 36px;
}
.mb-42 {
  margin-bottom: 42px;
}
.mb-44 {
  margin-bottom: 44px;
}
.mb-46 {
  margin-bottom: 46px;
}
.mb-54 {
  margin-bottom: 54px;
}

/******** PADDINGS **********/
.pl-24 {
  padding-left: 24px;
}

.p-16 {
  padding: 16px !important;
}

.pr-16 {
  padding-right: 16px;
}

.p-30 {
  padding: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

/******** WIDTH **********/
.w-47 {
  width: 47px !important;
}
.w-48 {
  width: 48px !important;
}

.w-52 {
  width: 52px !important;
}

.w-71 {
  width: 71px !important;
}

.w-84 {
  width: 84px !important;
}

.w-90 {
  width: 90px !important;
}

.w-95 {
  width: 95px !important;
}

.w-110 {
  width: 110px !important;
}

.w-120 {
  width: 120px !important;
}

.w-130 {
  width: 130px !important;
}

.w-140 {
  width: 140px !important;
}
.w-142 {
  width: 142px !important;
}

.w-150 {
  width: 150px !important;
}

.w-166 {
  width: 166px !important;
}
.w-171 {
  width: 171px !important;
}

.w-181 {
  width: 181px !important;
}
.w-188 {
  width: 188px !important;
}

.w-210 {
  width: 210px !important;
}
.w-256 {
  width: 256px !important;
}

.w-360 {
  width: 360px !important;
}

.w-430 {
  width: 430px !important;
}
.w-520 {
  width: 520px !important;
}

/******** HEIGHT**********/
.h-76 {
  height: 76px;
}

/******** TABLE-WIDTH **********/
.table-w-16 {
  min-width: 16px !important;
  max-width: 16px !important;
  width: 16px !important;
}

.table-w-32 {
  min-width: 32px !important;
  width: 32px !important;
  max-width: 32px !important;
}

.table-w-39 {
  min-width: 39px !important;
  width: 39px !important;
}
.table-w-48 {
  min-width: 48px !important;
  width: 48px !important;
}

.table-w-52 {
  min-width: 52px !important;
  width: 52px !important;
  max-width: 52px !important;
}

.table-w-71 {
  min-width: 71px !important;
  width: 71px !important;
  max-width: 71px !important;
}
.table-w-75 {
  min-width: 75px !important;
  width: 75px !important;
  max-width: 75px !important;
}
.table-w-81 {
  min-width: 81px !important;
  width: 81px !important;
}
.table-w-87 {
  min-width: 87px !important;
  width: 87px !important;
  max-width: 87px !important;
}

.table-w-90 {
  min-width: 90px !important;
  width: 90px !important;
  max-width: 90px !important;
}

.table-w-95 {
  min-width: 95px !important;
  width: 95px !important;
}

.table-w-104 {
  min-width: 104px !important;
  width: 104px !important;
  max-width: 104px !important;
}
.table-w-109 {
  min-width: 109px !important;
  width: 109px !important;
  max-width: 109px !important;
}
.table-w-116 {
  min-width: 116px !important;
  width: 116px !important;
  max-width: 116px !important;
}
.table-w-117 {
  min-width: 117px !important;
  width: 117px !important;
  max-width: 117px !important;
}

.table-w-120 {
  min-width: 120px !important;
  width: 120px !important;
}

.table-w-130 {
  min-width: 130px !important;
  width: 130px !important;
}
.table-w-133 {
  min-width: 133px !important;
  width: 133px !important;
}
.table-w-135 {
  min-width: 135px !important;
  width: 135px !important;
}

.table-w-140 {
  min-width: 140px !important;
  width: 140px !important;
}
.table-w-141 {
  min-width: 141px !important;
  width: 141px !important;
}
.table-w-144 {
  min-width: 144px !important;
  width: 144px !important;
}

.table-w-151 {
  min-width: 151px !important;
  width: 151px !important;
}
.table-w-155 {
  min-width: 155px !important;
  width: 155px !important;
  max-width: 155px !important;
}
.table-w-157 {
  min-width: 157px !important;
  width: 157px !important;
}
.table-w-161 {
  min-width: 161px !important;
  width: 161px !important;
}
.table-w-163 {
  min-width: 163px !important;
  width: 163px !important;
}
.table-w-166 {
  min-width: 166px !important;
  width: 166px !important;
}

.table-w-180 {
  min-width: 180px !important;
  width: 180px !important;
  max-width: 180px !important;
}
.table-w-202 {
  min-width: 202px !important;
  width: 202px !important;
}
.table-w-230 {
  min-width: 230px !important;
  width: 230px !important;
}

.table-w-302 {
  min-width: 302px !important;
  width: 302px !important;
  max-width: 302px !important;
}
.table-w-326 {
  min-width: 326px !important;
  width: 326px !important;
  max-width: 326px !important;
}
.table-w-360 {
  min-width: 360px !important;
  width: 360px !important;
  max-width: 360px !important;
}
.table-w-380 {
  min-width: 380px !important;
  width: 380px !important;
}

.table-w-425 {
  min-width: 425px !important;
  width: 425px !important;
}

.table-w-451 {
  min-width: 451px !important;
  width: 451px !important;
}
.table-w-539 {
  min-width: 539px !important;
  width: 539px !important;
  max-width: 539px !important;
}
.table-w-544 {
  min-width: 544px !important;
  width: 544px !important;
  max-width: 544px !important;
}
.table-w-610 {
  min-width: 610px !important;
  width: 610px !important;
  max-width: 610px !important;
}

/******** VALIDATION **********/
.error-validation-input {
  border: 1px solid $danger_500 !important;
  box-shadow: 0 0 0 3px $danger_200 !important;
  &:hover {
    border: 1px solid $danger_500 !important;
    box-shadow: 0 0 0 3px $danger_200 !important;
  }
  &:active {
    border: 1px solid $danger_500 !important;
    box-shadow: 0 0 0 3px $danger_200 !important;
  }
  &:focus-within {
    border: 1px solid $danger_500 !important;
    box-shadow: 0 0 0 3px $danger_200 !important;
  }
}

.error-validation-select {
  border: 1px solid $danger_500 !important;
  box-shadow: 0 0 0 3px $danger_200 !important;
  &:hover {
    border: 1px solid $danger_500 !important;
    box-shadow: 0 0 0 3px $danger_200 !important;
  }
}

/******** SCROLL/TRACK **********/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, $gray_secondary_400, $gray_secondary_400);
  border-radius: 10px;
  border: 1px solid $gray_secondary_400;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

/******** ANTD/BUTTON **********/
.ant-btn-variant-solid:disabled,
.ant-btn-variant-solid.ant-btn-disabled {
  background-color: $gray_secondary_900;
  opacity: 0.3;
  color: $gray_secondary_100;
}

/******** ANTD/TABS **********/
.ant-tabs-tabpane:focus-visible {
  outline: none !important;
}

/******** UTILS **********/

.cursor-p {
  cursor: pointer;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-icon {
  margin-inline-end: 0px !important;
}
