.groups-filter__container {
  display: flex;
  align-items: center;
}

.filter__all {
  min-width: 84px;
  display: flex;
  justify-content: center;
}

.filter__reprice-ready {
  min-width: 165px;
  display: flex;
  justify-content: center;
}

.filter__failed {
  min-width: 109px;
  display: flex;
  justify-content: center;
}

.filter__in-progress {
  min-width: 147.5px;
  display: flex;
  justify-content: center;
}
.filter__completed {
  min-width: 145px;
  display: flex;
  justify-content: center;
}
