@import "@shared/ui/_variables.scss";

.tm-tab-title {
  height: 60px;
  line-height: 60px !important;
}
.main-header-tm {
  padding: 16px 24px;
  border-bottom: 1px solid $gray_secondary_300;
  height: 84px;
}
.back_to-title {
  height: 52px;
}
.header__title {
  color: $gray_secondary_900;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.tm-block-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;

  .tm-filters-container {
    display: flex;
    align-items: center;
    padding: 12px 0px;
    .tm-filters {
      display: flex;
      align-items: center;
    }
  }
  .users-invite__btn {
    width: 205px;
  }
}
.block-tm {
  padding: 0px 24px 24px 24px;
}

.back_to_container-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  width: 150px;

  h5 {
    color: $gray_secondary_900;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-left: 8px;
  }
}
