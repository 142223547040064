@import "@shared/ui/_variables.scss";

.alert-custom {
  & > svg {
    margin-right: 12px;
  }
  &.default {
    background-color: $gray_secondary_200;
    border-color: $gray_secondary_400;
    color: $gray_secondary_800;
  }
  //   &.info {
  //     background-color: #e6f7ff;
  //     border-color: #91d5ff;
  //     color: #0050b3;
  //   }

  &.success {
    background-color: $green-100;
    border-color: $green-400;
    color: $green-800;
  }

  &.warning {
    background-color: $yellow-100;
    border-color: $yellow-400;
    color: $yellow-800;
  }

  &.error {
    background-color: $danger_100;
    border-color: $danger_400;
  }
}
