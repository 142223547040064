@import "@shared/ui/_variables.scss";
@import "@shared/ui/mixins.scss";

.form {
  padding: 24px 32px;
  width: 600px;
  &-block {
    display: flex;
    flex-direction: column;
    &-label {
      color: $gray_secondary_900;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 6px;
    }
    &__input-block {
      // width: 300px;
    }
    &__input {
      color: $gray_secondary_900;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 0px 10px;
      width: 300px;
    }
    &__disabled {
      width: 300px;
      display: flex;
      align-items: center;
      height: 38px;
      padding: 9px 16px;
      background: $gray_secondary_100;
      border: 1px dashed $gray_secondary_300;
      border-radius: 6px;
      margin-top: 6px;
    }
  }
  &-delAccount-block {
    margin-top: 24px;
    &__title {
      color: $gray_secondary_900;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 21.6px;
      text-align: start;
      margin-bottom: 4px;
    }
    &__subtitle {
      color: $gray_secondary_900;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: start;
    }
    .del-account-btn {
      color: $danger_500 !important;
      border-color: $danger_300 !important;
      :hover {
        color: $danger_500 !important;
        border-color: $danger_300 !important;
      }
    }
  }
  .error-validation-message-del-acc {
    position: absolute;
    font-size: 12px;
    margin-top: 42px;
    color: $danger_500;
    left: 2px;
  }
}
.error-validation-message-fullname {
  position: absolute;
  font-size: 12px;
  margin-top: 42px;
  color: $danger_500;
  left: 2px;
}
