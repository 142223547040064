@import "@shared/ui/_variables.scss";

.notification-item__container {
  padding: 24px;
  display: flex;
}

.notification-item__badge {
  width: 8px;
  display: flex;
  align-items: center;
}

.notification-item__content {
  margin-left: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .notification-item__btn {
    width: 132px;
    padding: 0 16px;
  }
}

.notification-item__text, .notification-item__time {
  font-family: 'Inter-400';
  font-weight: 400!important;
  font-size: 14px!important;
  line-height: 21px!important;
}

.notification-item__time {
  font-family: 'Inter-400';
  font-weight: 400!important;
  margin: 0!important;
  color: $gray_secondary_600!important;
  line-height: 20px!important;
}