@import "@shared/ui/_variables.scss";

.radio-block {
  margin: 24px 0px;
  &-item {
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    input {
      margin-right: 8px;
    }
  }
  &-description {
    margin-top: 16px;
  }
  &-input {
    margin-top: 16px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    span {
      color: $gray_secondary_900;
      display: inline-block;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
  }
}
