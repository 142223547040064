@import "@shared/ui/_variables.scss";

.pagination__text {
  font-family: "Inter-300";
  font-weight: 400;
  margin-right: 16px;
  color: $gray_secondary_600;
}

.pagination-arrows {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    &:last-child {
      transform: rotate(180deg);
      margin-left: 16px;
    }
  }

  &.disabled-prev {
    svg {
      &:first-child {
        cursor: not-allowed !important;
        pointer-events: none;
      }
    }
  }

  &.disabled-next {
    svg {
      &:last-child {
        cursor: not-allowed !important;
        pointer-events: none;
      }
    }
  }
}
