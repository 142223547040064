@import "@shared/ui/_variables.scss";

.signup-form_wrapper {
  .input-label__wrapper {
    margin-bottom: 24px;
  }
  .signup-form__label {
    color: $gray_secondary_900;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .signup-form-btn {
    margin: 24px 0px;
  }
  .signup-form__text {
    color: $gray_secondary_600;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .validation-email {
    color: $danger_500;
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-align: start;
  }
  .custom-height {
    height: 82px;
  }
  .alert__body {
    p {
      text-align: start;
    }
    ul {
      margin-left: 20px;
      li {
        text-align: start;
      }
    }
  }
}
