@import "@shared/ui/_variables.scss";

.modal__edit-user-content {
  padding: 24px;
  height: 112px;
  .edit_body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .edit-user-block {
      width: 230px;
      .user-label {
        color: $gray_secondary_900;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .user-name {
        overflow: hidden;
        display: flex;
        align-items: center;
        height: 38px;
        padding: 9px 16px;
        background: $gray_secondary_100;
        border: 1px dashed $gray_secondary_300;
        border-radius: 6px;
        margin-top: 6px;
      }
    }
    .edit-role-block {
      width: 180px;
      .role-label {
        color: $gray_secondary_900;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .role-select {
        margin-top: 6px;
      }
    }
  }
}

.users-modal-edit__wrapper {
  padding: 24px;
}
