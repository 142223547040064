@import "@shared/ui/mixins.scss";
@import "@shared/ui/_variables.scss";

.groups-strategy-rule__wrapper-elasticity {
  @include groups-wrapper();
}

.groups-strategy-rule__title-elasticity {
  @include groups-title();
}

.groups-strategy-rule__subtitle-elasticity {
  @include groups-subtitle();
}
.groups-strategy-rule__content-elasticity {
  .groups-strategy-rule__content-block {
    display: flex;
    justify-content: space-between;
  }
  .groups-strategy-rule__content-item {
    flex: 6;
  }
  .groups-strategy-rule__text-elasticity {
    color: $gray_secondary_900;
    font-family: "Inter-600";
  }
  .groups-strategy-rule__subtitle-text {
    color: $gray_secondary_600;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    padding: 3px 0px 6px 0px;
  }
  .groups-strategy-rule__content-item-block {
    margin-bottom: 32px;
    width: 380px;
  }
  .groups-strategy-rule-select {
    width: 380px;
    margin-right: 32px;

    .ant-select-arrow {
      transform: rotate(-90deg);
    }
    .ant-select-open {
      .ant-select-arrow {
        transform: rotate(90deg);
      }
    }
  }
  .groups-strategy-rule__chart {
    width: 380px;
    height: 170px;
  }
  .groups-strategy-rule__list {
    margin-left: 24px;
  }
}
