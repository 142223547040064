@import "@shared/ui/_variables.scss";

.score-item {
  width: 4px;
  height: 10px;
  border-radius: 2px;
  margin-left: 1px;
  margin-right: 1px;
  background: $gray_secondary_700;
}
.two {
  background: $gray_secondary_300;
}
.four {
  background: $gray_secondary_400;
}
.six {
  background: $gray_secondary_500;
}
.eight {
  background: $gray_secondary_600;
}
