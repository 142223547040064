@import "@shared/ui/_variables.scss";

.items-bulk__container {
  padding: 16px 0;
  display: flex;
  align-items: center;

  .input-custom__wrapper {
    input {
      width: 240px;
    }
  }
  .badge-custom {
    background-color: $gray_secondary_200;
    color: $gray_secondary_900;
  }
}

.items-select__container {
  display: flex;
  align-items: center;
}

.items-select__title {
  margin-right: 8px;
  color: $gray_secondary_900 !important;
  font-family: "Inter-400";
  font-weight: 400;
}

.items-select__wrapper {
  min-width: 187px !important;
  max-width: 187px !important;
  margin-right: 32px;

  .ant-select-arrow {
    transform: rotate(-90deg);
  }

  .ant-select-open {
    .ant-select-arrow {
      transform: rotate(90deg);
    }
  }
}

.ant-modal-header {
  border-bottom: 1px solid #f0f0f0 !important;
}

.ant-modal-footer {
  border-top: 1px solid #f0f0f0 !important;
}

.confirm-modal-body {
  height: 120px;
  margin: 24px;
}

.items__confirm-btn {
  display: flex;
  background: $danger_600;
  color: $white;
}
.items__footer {
  display: flex;
  justify-content: flex-end;
  button {
    width: 100px;
    margin-left: 16px;
  }
}
.items-bulk__clear {
  color: $gray_secondary_600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
.items-bulk__switch {
  flex-grow: 1;
  text-align: end;
}
