@import "@shared/ui/_variables.scss";

.login-close__icon {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.error__text {
  color: $danger_500;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}
