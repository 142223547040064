@import "@shared/ui/_variables.scss";

.item-overview {
  .overview-current-price-block {
    display: flex;
    flex: 1 1;
    padding: 16px;
    border-radius: 8px;
    background-color: $gray_secondary_100;
  }
  .item-overview-repricing {
    margin: 20px 24px 24px 24px;
  }
  .item-overview-configuration {
    margin: 24px 0px;
    padding: 24px;
    border-top: 1px solid $gray_secondary_300;
    .summary__overview-key {
      width: 200px;
    }
  }
  .overview-current-price-item {
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .overview-title {
    font-family: "Inter-600";
    color: $gray_secondary_900;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
  }
  .overview-last-reprice {
    margin-bottom: 8px;
    color: $gray_secondary_600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    svg {
      margin-left: 4px;
    }
  }
  .overview-last-reprice-date {
    color: $gray_secondary_900;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  .overview-last-reprice-no-date {
    color: $gray_secondary_900;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    width: 50%;
  }
  .overview-current-price {
    color: $gray_secondary_900;
    font-family: "Inter-600" !important;
    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
  }
  .add-exception-btn-block {
    width: 173px;
  }
  .available-items {
    font-weight: 400;
  }
}
.modal__exception {
  .modal__exception-content {
    position: relative;
    padding: 24px 24px 30px 24px;
    color: $gray_secondary_900;
    font-size: 16px;
    line-height: 24px;
    .select-error {
      position: absolute;
      bottom: 8px;
      color: $danger-500;
      font-size: 14px;
      line-height: 20px;
    }
    .image-title {
      width: 375px;
      .product-id {
        color: $gray_secondary_600;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
      .product-name {
        color: $gray_secondary_900;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
      }
    }
    .costs-title {
      display: flex;
      justify-content: space-between;
      width: 380px;
      .title {
        color: $gray_secondary_900;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .label {
      position: absolute;
      bottom: 70px;
      z-index: 9;
      color: $gray_secondary_900;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .input-error {
      bottom: -21px;
    }
  }
  .modal__exception-select {
    margin-bottom: 30px;
  }
  .modal__exception-input {
    width: 142px;
    .input-wrapper {
      width: 100%;
      input {
        font-size: 16px;
        line-height: 20px;
        width: inherit;
        padding: 0px 16px;
      }
    }
  }
  .modal-divider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
  }
  .title-select {
    color: $gray_secondary_900;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
  }
  .title-input {
    color: $gray_secondary_900;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
  }
}

.overview-new-reprice {
  color: $gray_secondary_900;
  font-family: "Inter-600" !important;
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
  display: flex;
  align-items: center;
}

.custom-limit-actions {
  display: flex;
  width: 60px;
  justify-content: space-between;
  align-items: center;
  .icon-action {
    cursor: pointer;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}
.exception-item {
  justify-content: space-between;
  width: 61%;
  align-items: center;
}
.overview-current-price-update {
  color: $gray_secondary_600;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

///////////////////////////
.badge-new-price-positive,
.badge-new-price-negative {
  font-size: 12px;
  line-height: 16px;
  margin-left: 8px;
}
.badge-new-price-positive {
  color: $green-700;
  background-color: $green-100;
}
.badge-new-price-negative {
  color: $danger_500;
  background-color: $danger_100;
}
.overview-current-price-lock {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-self: center;
  color: $gray_secondary_600;
  cursor: pointer;
}
.overview-current-price-na {
  color: $gray_secondary_600;
  font-size: 21px;
  font-weight: 600;

  line-height: 25px;
  display: flex;
  align-items: center;
}

.overview-last-reprice-block {
  display: flex;
  justify-content: space-between;
  width: max-content;
  width: 210px;
}
