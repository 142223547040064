@import "@shared/ui/_variables.scss";

.input-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: border-color 0.3s ease;
  overflow-y: hidden;
  height: 38px;
}

.input-wrapper:hover,
.input-wrapper:focus-within {
  border-color: $gray_secondary_900;
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.1);
}

.icon {
  display: flex;
  align-items: center;
  transition: border-color 0.2s ease, background-color 0.2s ease;
  min-width: 30px;
  color: $gray_secondary_900;
}

.left-icon {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray_secondary_100;
  border-right: 1px solid $gray_secondary_300;
  pointer-events: none;
}

.right-icon {
  border-left: 1px solid $gray_secondary_300;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray_secondary_100;
  border-color: $gray_secondary_300;
  pointer-events: none;
}

.custom-input {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  line-height: 1.5;
  font-size: 16px;
  margin-left: 8px;
  vertical-align: middle;
  height: inherit;
  font-family: "Inter-300";
}

.input-wrapper .left-icon + .custom-input {
  padding-left: 0;
}

.input-wrapper .custom-input + .right-icon {
  padding-right: 0;
}
.left {
  height: 18px;
}
